import React from 'react';
import { Button } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { GettingStarted, PrivateLayout, ResourceContent } from '../components';
import { FeatureGuard } from '../components/multi-tenant/FeatureGuard';
import { navigateToServiceRequest } from '../redux';

export const HomeView = () => {
  const dispatch = useDispatch();

  const renderButton = () => {
    return (
      <>
        <FeatureGuard contentKey='service-request.manage'>
          <Button
            className='text-nowrap'
            variant='secondary'
            size='sm'
            onClick={() => dispatch(navigateToServiceRequest())}
            data-testid='cmx__new-request-button'>
            START A NEW REQUEST
          </Button>
        </FeatureGuard>
      </>
    );
  };

  return (
    <>
      <FeatureGuard contentKey='home.layout'>
        <PrivateLayout subtoolbarChildren={renderButton()}>
          <h3 className='text-primary'>
            <ResourceContent contentKey='home.title' />
          </h3>
          <GettingStarted />
        </PrivateLayout>
      </FeatureGuard>
      <FeatureGuard contentKey='home-phyz.layout'>
        <PrivateLayout subtoolbarChildren={renderButton()} pageTitle='FRONT DESK'>
          <GettingStarted />
        </PrivateLayout>
      </FeatureGuard>
    </>
  );
};
