import React, { PropsWithChildren } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { ResourceContent } from './multi-tenant';

interface SubToolbarProps extends PropsWithChildren<unknown> {
  pageTitle?: string;
  contentKey?: string;
}

export const SubToolbar = ({ children, pageTitle, contentKey }: SubToolbarProps) => {
  return (
    <Container fluid className='d-flex align-items-center bg-primary flex-wrap h-auto py-3'>
      <Container>
        <Row lg={2} xs={1} className='justify-content-between align-items-center'>
          <Col>
            <h4
              className='text-uppercase text-white font-weight-bold text-truncate'
              data-testid='cmx__subtoolbar-title'>
              {pageTitle?.length ? pageTitle : <ResourceContent contentKey={contentKey || ''} />}
            </h4>
          </Col>
          <Col className='justify-content-end d-flex'>{children}</Col>
        </Row>
      </Container>
    </Container>
  );
};
