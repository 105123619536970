import { ConditionDefinition } from '@data-driven-forms/react-form-renderer';
import { AnyObject } from '@data-driven-forms/react-form-renderer/dist/cjs/common';
import validatorTypes from '@data-driven-forms/react-form-renderer/dist/cjs/validator-types';
import { FIELD_NAMES, StepNames } from '../..';
import { AnyType } from '../../../interfaces';
import {
  compareAndGetNonHiddenField,
  DBFormSchema,
  fieldAdministrationInfo,
  fieldHasInsuranceTogglerSection,
  fieldTextSection,
  fieldTypeAheadInsuranceProvidersSection,
  fieldUploadSection,
  headerSection,
  spacerSection,
  medicscanUploadSection,
  fieldDateSection,
  fieldRadioSection,
  fieldIsPrimaryCardholderToggler,
  fieldPlanName,
  fieldTypeAheadPayer,
  fieldSelectSupplimentPlanLetter,
  fieldSelectCardholderRelationshipsSection,
  fieldTextLabel,
  fieldLast4SSNInput
} from '../field-helpers';

const { insuranceFields, patientFields } = FIELD_NAMES;

export const insuranceTabFields = (schema: DBFormSchema, nextStep?: StepNames | AnyObject) => {
  const insuranceTab = schema?.insurance_tab;
  if (!insuranceTab || insuranceTab?.hideTab) return null;

  return {
    title: insuranceTab?.label ?? 'Insurance',
    name: StepNames.INSURANCE,
    nextStep: insuranceTab?.nextStep ?? nextStep,
    fields: [
      headerSection(schema?.insurance_information_header?.label, schema?.insurance_information_header),
      fieldHasInsuranceTogglerSection(patientFields.hasInsurance, schema?.[patientFields.hasInsurance], {
        sizes: { md: 7 }
      }),

      // Medicare sub-section
      headerSection(schema?.insurance_info_medicare_header?.label, schema?.insurance_info_medicare_header, {
        subsection: true,
        ...hasInsuranceCondition
      }),
      fieldTextLabel(insuranceFields.eitherBiOrLast4SsnText, schema?.[insuranceFields.eitherBiOrLast4SsnText], {
        sizes: { md: 12 }
      }),
      fieldTextSection(
        insuranceFields.medicareBeneficiaryIDNumber,
        schema?.[insuranceFields.medicareBeneficiaryIDNumber],
        {
          sizes: { md: 4 },
          validate: [
            {
              type: validatorTypes.PATTERN,
              pattern: /\b[1-9](?![sloibzSLOIBZ])[A-Z](?![sloibzSLOIBZ)])[A-Z1-9][1-9]-(?![sloibzSLOIBZ])[A-Z](?![sloibzSLOIBZ])[A-Z1-9][1-9]-(?![sloibzSLOIBZ])[A-Z](?![sloibzSLOIBZ])[A-Z][1-9]{2}\b$/,
              message: 'Incorrect Medicare Beneficiary ID format'
            }
          ],
          condition: [
            {
              when: patientFields.hasInsurance,
              is: 'yes'
            },
            {
              when: insuranceFields.last4SSN,
              is: (value: any) => {
                if (value) return false;
                return true;
              }
            }
          ]
        }
      ),
      fieldTextSection(
        insuranceFields.medicareBeneficiaryIDNumber,
        schema?.[insuranceFields.medicareBeneficiaryIDNumberOptional],
        {
          sizes: { md: 4 },
          validate: [
            {
              type: validatorTypes.PATTERN,
              pattern: /\b[1-9](?![sloibzSLOIBZ])[A-Z](?![sloibzSLOIBZ)])[A-Z1-9][1-9]-(?![sloibzSLOIBZ])[A-Z](?![sloibzSLOIBZ])[A-Z1-9][1-9]-(?![sloibzSLOIBZ])[A-Z](?![sloibzSLOIBZ])[A-Z][1-9]{2}\b$/,
              message: 'Incorrect Medicare Beneficiary ID format'
            }
          ],
          optional: true,
          condition: [
            {
              when: patientFields.hasInsurance,
              is: 'yes'
            },
            {
              when: insuranceFields.last4SSN,
              is: (value: any) => {
                if (value) return true;
                return false;
              }
            }
          ]
        }
      ),
      fieldLast4SSNInput(insuranceFields.last4SSN, schema?.[insuranceFields.last4SSN], {
        sizes: { md: 4 },
        validate: [
          {
            type: validatorTypes.PATTERN,
            pattern: /\d{4}/,
            message: 'Incorrect Last 4 of SSN format'
          }
        ],
        condition: [
          {
            when: patientFields.hasInsurance,
            is: 'yes'
          },
          {
            when: insuranceFields.medicareBeneficiaryIDNumber,
            is: (value: any) => {
              if (value) return false;
              return true;
            }
          }
        ]
      }),
      fieldLast4SSNInput(insuranceFields.last4SSN, schema?.[insuranceFields.last4SSNOptional], {
        sizes: { md: 4 },
        optional: true,
        validate: [
          {
            type: validatorTypes.PATTERN,
            pattern: /\d{4}/,
            message: 'Incorrect Last 4 of SSN format'
          }
        ],
        condition: [
          {
            when: patientFields.hasInsurance,
            is: 'yes'
          },
          {
            when: insuranceFields.medicareBeneficiaryIDNumber,
            is: (value: any) => {
              if (value) return true;
              return false;
            }
          }
        ]
      }),
      // End of Medicare sub-section

      medicscanUploadSection(insuranceFields.medicscanUpload, schema?.[insuranceFields.medicscanUpload], {
        sizes: { md: 5 }
      }),

      spacerSection('insurance_1', { sizes: { sm: 12 }, ...hasInsuranceCondition }),
      fieldTypeAheadInsuranceProvidersSection(patientFields.insurances, schema?.[patientFields.insurances], {
        sizes: { md: 4 },
        ...hasInsuranceCondition
      }),
      spacerSection('insurance_2', { sizes: { sm: 12 }, ...hasInsuranceCondition }),
      fieldTextSection(patientFields.otherInsurance, schema?.[patientFields.otherInsurance], {
        sizes: { md: 4 },
        condition: [
          {
            when: patientFields.insurances,
            is: (insurances: AnyType[] = []) => insurances.find((insurance) => insurance.value === 'other')
          },
          hasInsuranceCondition.condition
        ],
        max: 30
      }),
      spacerSection('insurance_3', { sizes: { sm: 12 }, ...hasInsuranceCondition }),
      fieldAdministrationInfo(insuranceFields.medicaidPending, schema?.[insuranceFields.medicaidPending], {
        sizes: { md: 7 }
      }),
      spacerSection('insurance_3_1', { sizes: { sm: 12 }, ...hasInsuranceCondition }),
      // Medical Insurance section
      headerSection(schema?.medical_insurance_header?.label, schema?.medical_insurance_header, {
        ...hasInsuranceCondition
      }),
      // Primary sub-section
      headerSection(schema?.medical_insurance_primary_header?.label, schema?.medical_insurance_primary_header, {
        subsection: true,
        ...hasInsuranceCondition
      }),
      fieldTypeAheadPayer(insuranceFields.primaryPlanPayerName, schema?.[insuranceFields.primaryPlanPayerName], {
        sizes: { md: 4 },
        validate: [
          {
            type: 'name'
          }
        ],
        ...hasInsuranceCondition
      }),
      fieldTextSection(insuranceFields.primaryPlanName, schema?.[insuranceFields.primaryPlanName], {
        sizes: { md: 4 },
        validate: [
          {
            type: 'name'
          }
        ],
        ...hasInsuranceCondition
      }),
      fieldTextSection(insuranceFields.primaryPlanCardholderId, schema?.[insuranceFields.primaryPlanCardholderId], {
        sizes: { md: 3 },
        validate: [
          {
            type: 'id',
            strict: true
          }
        ],
        ...cardHolderAndGroupNumberCondition
      }),
      fieldTextSection(insuranceFields.primaryPlanCardholderId, schema?.[insuranceFields.primaryPlanCardholderIdOpt], {
        sizes: { md: 3 },
        optional: true,
        validate: [
          {
            type: 'id',
            strict: true
          }
        ],
        ...cardHolderAndGroupNumberOptionalCondition
      }),
      fieldTextSection(
        insuranceFields.primaryPlanCardholderId,
        schema?.[insuranceFields.primaryMedicareBeneficiaryIdNumber],
        {
          sizes: { md: 4 },
          validate: [
            {
              type: 'id',
              strict: true
            }
          ],
          ...cardHolderAndGroupNumberOptionalCondition
        }
      ),
      fieldIsPrimaryCardholderToggler(
        insuranceFields.primaryIsCardholder,
        schema?.[insuranceFields.primaryIsCardholder],
        {
          sizes: { md: 4 },
          ...hasInsuranceCondition
        }
      ),
      fieldTextSection(insuranceFields.primaryPolicyHolderName, schema?.[insuranceFields.primaryPolicyHolderName], {
        sizes: { md: 4 },
        validate: [
          {
            type: 'id',
            strict: true
          }
        ],
        ...hasInsuranceCondition
      }),
      fieldTextSection(insuranceFields.primaryPlanGroupNumber, schema?.[insuranceFields.primaryPlanGroupNumber], {
        sizes: { md: 2 },
        validate: [
          {
            type: 'id',
            strict: true
          }
        ],
        ...cardHolderAndGroupNumberCondition
      }),
      fieldTextSection(insuranceFields.primaryPlanGroupNumber, schema?.[insuranceFields.primaryPlanGroupNumberOpt], {
        sizes: { md: 2 },
        optional: true,
        validate: [
          {
            type: 'id',
            strict: true
          }
        ],
        ...cardHolderAndGroupNumberOptionalCondition
      }),
      fieldTextSection(insuranceFields.primaryPlanPhoneNumber, schema?.[insuranceFields.primaryPlanPhoneNumber], {
        type: 'tel',
        sizes: { md: 3 },
        validate: [
          {
            type: 'phone'
          }
        ],
        ...hasInsuranceCondition
      }),

      // Primary Subscriber Required Fields
      fieldTextSection(
        insuranceFields.primarySubscriberFirstName,
        schema?.[insuranceFields.primarySubscriberFirstName],
        {
          sizes: { md: 4 },
          validate: [
            {
              type: 'name'
            }
          ],
          ...hasInsuranceConditionAndIsPrimaryCardHolder(insuranceFields.primaryIsCardholder, 'N')
        }
      ),
      fieldTextSection(insuranceFields.primarySubscriberLastName, schema?.[insuranceFields.primarySubscriberLastName], {
        sizes: { md: 4 },
        validate: [
          {
            type: 'name'
          }
        ],
        ...hasInsuranceConditionAndIsPrimaryCardHolder(insuranceFields.primaryIsCardholder, 'N')
      }),
      fieldDateSection(insuranceFields.primarySubscriberDob, schema?.[insuranceFields.primarySubscriberDob], {
        sizes: { md: 4 },
        ...hasInsuranceConditionAndIsPrimaryCardHolder(insuranceFields.primaryIsCardholder, 'N', true)
      }),
      fieldRadioSection(insuranceFields.primarySubscriberGender, schema?.[insuranceFields.primarySubscriberGender], {
        sizes: { md: 4 },
        options: [
          { value: 'M', label: 'Male' },
          { value: 'F', label: 'Female' }
        ],
        ...hasInsuranceConditionAndIsPrimaryCardHolder(insuranceFields.primaryIsCardholder, 'N')
      }),
      fieldTextSection(insuranceFields.primaryEmployer, schema?.[insuranceFields.primaryEmployer], {
        sizes: { md: 4 },
        validate: [
          {
            type: 'name'
          }
        ],
        ...hasInsuranceCondition
      }),
      fieldSelectCardholderRelationshipsSection(
        insuranceFields.primarySubscriberRelationship,
        schema?.[insuranceFields.primarySubscriberRelationship],
        {
          sizes: { md: 4 },
          ...hasInsuranceConditionAndIsPrimaryCardHolder(insuranceFields.primaryIsCardholder, 'N', true)
        }
      ),
      spacerSection('insurance_5', { sizes: { sm: 12 }, ...hasInsuranceCondition }),

      // Secondary sub-section
      headerSection(schema?.medical_insurance_secondary_header?.label, schema?.medical_insurance_secondary_header, {
        subsection: true,
        ...hasInsuranceCondition
      }),
      fieldTypeAheadPayer(insuranceFields.secondaryPlanPayerName, schema?.[insuranceFields.secondaryPlanPayerName], {
        sizes: { md: 4 },
        validate: [
          {
            type: 'name'
          }
        ],
        ...hasInsuranceCondition
      }),
      fieldPlanName(insuranceFields.secondaryPlanName, schema?.[insuranceFields.secondaryPlanName], {
        sizes: { md: 4 },
        validate: [
          {
            type: 'name'
          }
        ],
        ...hasInsuranceCondition
      }),
      fieldTextSection(insuranceFields.secondaryPlanCardholderId, schema?.[insuranceFields.secondaryPlanCardholderId], {
        sizes: { md: 3 },
        validate: [
          {
            type: 'id',
            strict: true
          }
        ],
        ...cardholderIdConditions(
          compareAndGetNonHiddenField(
            insuranceFields.secondaryPlanPayerName,
            insuranceFields.secondaryPlanName,
            schema
          ),
          true
        )
      }),
      fieldTextSection(
        insuranceFields.secondaryPlanCardholderId,
        schema?.[insuranceFields.secondaryPlanCardholderIdOpt],
        {
          sizes: { md: 3 },
          validate: [
            {
              type: 'id',
              strict: true
            }
          ],
          ...cardholderIdConditions(
            compareAndGetNonHiddenField(
              insuranceFields.secondaryPlanPayerName,
              insuranceFields.secondaryPlanName,
              schema
            ),
            false
          )
        }
      ),
      fieldIsPrimaryCardholderToggler(
        insuranceFields.secondaryIsCardholder,
        schema?.[insuranceFields.secondaryIsCardholder],
        {
          sizes: { md: 4 },
          ...hasInsuranceCondition
        }
      ),
      fieldTextSection(insuranceFields.secondaryPolicyHolderName, schema?.[insuranceFields.secondaryPolicyHolderName], {
        sizes: { md: 4 },
        validate: [
          {
            type: 'id',
            strict: true
          }
        ],
        ...hasInsuranceCondition
      }),
      fieldTextSection(insuranceFields.secondaryPlanGroupNumber, schema?.[insuranceFields.secondaryPlanGroupNumber], {
        sizes: { md: 2 },
        validate: [
          {
            type: 'id',
            strict: true
          }
        ],
        ...cardholderIdConditions(insuranceFields.secondaryPlanName, true)
      }),
      fieldTextSection(
        insuranceFields.secondaryPlanGroupNumber,
        schema?.[insuranceFields.secondaryPlanGroupNumberOpt],
        {
          sizes: { md: 2 },
          validate: [
            {
              type: 'id',
              strict: true
            }
          ],
          ...cardholderIdConditions(insuranceFields.secondaryPlanName, false)
        }
      ),
      fieldTextSection(insuranceFields.secondaryPlanPhoneNumber, schema?.[insuranceFields.secondaryPlanPhoneNumber], {
        type: 'tel',
        sizes: { md: 3 },
        validate: [
          {
            type: 'phone'
          }
        ],
        ...hasInsuranceCondition
      }),

      // Secondary Subscriber Required Fields
      fieldTextSection(
        insuranceFields.secondarySubscriberFirstName,
        schema?.[insuranceFields.secondarySubscriberFirstName],
        {
          sizes: { md: 4 },
          validate: [
            {
              type: 'name'
            }
          ],
          ...hasInsuranceConditionAndIsPrimaryCardHolder(insuranceFields.secondaryIsCardholder, 'N')
        }
      ),
      fieldTextSection(
        insuranceFields.secondarySubscriberLastName,
        schema?.[insuranceFields.secondarySubscriberLastName],
        {
          sizes: { md: 4 },
          validate: [
            {
              type: 'name'
            }
          ],
          ...hasInsuranceConditionAndIsPrimaryCardHolder(insuranceFields.secondaryIsCardholder, 'N')
        }
      ),
      fieldDateSection(insuranceFields.secondarySubscriberDob, schema?.[insuranceFields.secondarySubscriberDob], {
        sizes: { md: 4 },
        ...secondaryFieldEnableCondition(
          compareAndGetNonHiddenField(
            insuranceFields.secondaryPlanPayerName,
            insuranceFields.secondaryPlanName,
            schema
          ),
          true
        )
      }),
      fieldDateSection(insuranceFields.secondarySubscriberDob, schema?.[insuranceFields.secondarySubscriberDobOpt], {
        sizes: { md: 4 },
        optional: true,
        ...secondaryFieldEnableCondition(
          compareAndGetNonHiddenField(
            insuranceFields.secondaryPlanPayerName,
            insuranceFields.secondaryPlanName,
            schema
          ),
          false
        )
      }),
      fieldRadioSection(
        insuranceFields.secondarySubscriberGender,
        schema?.[insuranceFields.secondarySubscriberGender],
        {
          sizes: { md: 4 },
          options: [
            { value: 'M', label: 'Male' },
            { value: 'F', label: 'Female' }
          ],
          ...hasInsuranceConditionAndIsPrimaryCardHolder(insuranceFields.secondaryIsCardholder, 'N')
        }
      ),
      fieldTextSection(insuranceFields.secondaryEmployer, schema?.[insuranceFields.secondaryEmployer], {
        sizes: { md: 4 },
        validate: [
          {
            type: 'name'
          }
        ],
        ...hasInsuranceCondition
      }),
      fieldSelectCardholderRelationshipsSection(
        insuranceFields.secondarySubscriberRelationship,
        schema?.[insuranceFields.secondarySubscriberRelationship],
        {
          sizes: { md: 4 },
          ...secondaryFieldEnableCondition(
            compareAndGetNonHiddenField(
              insuranceFields.secondaryPlanPayerName,
              insuranceFields.secondaryPlanName,
              schema
            ),
            true
          )
        }
      ),
      fieldSelectCardholderRelationshipsSection(
        insuranceFields.secondarySubscriberRelationship,
        schema?.[insuranceFields.secondarySubscriberRelationshipOpt],
        {
          sizes: { md: 4 },
          optional: true,
          ...secondaryFieldEnableCondition(
            compareAndGetNonHiddenField(
              insuranceFields.secondaryPlanPayerName,
              insuranceFields.secondaryPlanName,
              schema
            ),
            false
          )
        }
      ),
      fieldRadioSection(
        insuranceFields.secondaryIsMedicalSupplementPlan,
        schema?.[insuranceFields.secondaryIsMedicalSupplementPlan],
        {
          sizes: { md: 4 },
          required: true,
          options: [
            { value: 'Y', label: 'Yes' },
            { value: 'N', label: 'No' },
            { value: 'unknown', label: 'Unknown' }
          ],
          className: 'vertical-options',
          ...cardholderIdConditions(
            compareAndGetNonHiddenField(
              insuranceFields.secondaryPlanPayerName,
              insuranceFields.secondaryPlanName,
              schema
            ),
            true
          )
        }
      ),
      fieldRadioSection(
        insuranceFields.secondaryIsMedicalSupplementPlan,
        schema?.[insuranceFields.secondaryIsMedicalSupplementPlan],
        {
          sizes: { md: 4 },
          required: false,
          options: [
            { value: 'Y', label: 'Yes' },
            { value: 'N', label: 'No' },
            { value: 'unknown', label: 'Unknown' }
          ],
          className: 'vertical-options',
          ...cardholderIdConditions(
            compareAndGetNonHiddenField(
              insuranceFields.secondaryPlanPayerName,
              insuranceFields.secondaryPlanName,
              schema
            ),
            false
          )
        }
      ),
      fieldSelectSupplimentPlanLetter(
        insuranceFields.secondaryMedicalSupplementPlanLetter,
        schema?.[insuranceFields.secondaryMedicalSupplementPlanLetter],
        {
          sizes: { md: 4 },
          ...hasInsuranceAndSupplementPlan
        }
      ),
      spacerSection('insurance_7', { sizes: { sm: 12 }, ...hasInsuranceCondition }),

      // Tertiary sub-section
      headerSection(schema?.medical_insurance_tertiary_header?.label, schema?.medical_insurance_tertiary_header, {
        subsection: true,
        ...hasInsuranceCondition
      }),
      fieldTypeAheadPayer(insuranceFields.tertiaryPlanPayerName, schema?.[insuranceFields.tertiaryPlanPayerName], {
        sizes: { md: 4 },
        validate: [
          {
            type: 'name'
          }
        ],
        ...hasInsuranceCondition
      }),
      fieldTextSection(insuranceFields.tertiaryPlanCardholderId, schema?.[insuranceFields.tertiaryPlanCardholderId], {
        sizes: { md: 3 },
        validate: [
          {
            type: 'id',
            strict: true
          }
        ],
        ...cardholderIdConditions(insuranceFields.tertiaryPlanPayerName, true)
      }),
      fieldTextSection(
        insuranceFields.tertiaryPlanCardholderId,
        schema?.[insuranceFields.tertiaryPlanCardholderIdOpt],
        {
          sizes: { md: 3 },
          validate: [
            {
              type: 'id',
              strict: true
            }
          ],
          ...cardholderIdConditions(insuranceFields.tertiaryPlanPayerName, false)
        }
      ),
      fieldIsPrimaryCardholderToggler(
        insuranceFields.tertiaryIsCardholder,
        schema?.[insuranceFields.tertiaryIsCardholder],
        {
          sizes: { md: 4 },
          ...hasInsuranceCondition
        }
      ),

      // tertiary Required fields
      fieldTextSection(
        insuranceFields.tertiarySubscriberFirstName,
        schema?.[insuranceFields.tertiarySubscriberFirstName],
        {
          sizes: { md: 4 },
          validate: [
            {
              type: 'name'
            }
          ],
          ...hasInsuranceConditionAndIsPrimaryCardHolder(insuranceFields.tertiaryIsCardholder, 'N')
        }
      ),
      fieldTextSection(
        insuranceFields.tertiarySubscriberLastName,
        schema?.[insuranceFields.tertiarySubscriberLastName],
        {
          sizes: { md: 4 },
          validate: [
            {
              type: 'name'
            }
          ],
          ...hasInsuranceConditionAndIsPrimaryCardHolder(insuranceFields.tertiaryIsCardholder, 'N')
        }
      ),
      fieldDateSection(insuranceFields.tertiarySubscriberDob, schema?.[insuranceFields.tertiarySubscriberDob], {
        sizes: { md: 4 },
        ...hasInsuranceConditionAndIsPrimaryCardHolder(insuranceFields.tertiaryIsCardholder, 'N')
      }),
      fieldRadioSection(insuranceFields.tertiarySubscriberGender, schema?.[insuranceFields.tertiarySubscriberGender], {
        sizes: { md: 4 },
        options: [
          { value: 'M', label: 'Male' },
          { value: 'F', label: 'Female' }
        ],
        ...hasInsuranceConditionAndIsPrimaryCardHolder(insuranceFields.tertiaryIsCardholder, 'N')
      }),
      spacerSection('insurance_9', { sizes: { sm: 12 }, ...hasInsuranceCondition }),

      // PHARMACY OR PRESCRIPTION INSURANCE section
      headerSection(schema?.pharmacy_insurance_header?.label, schema?.pharmacy_insurance_header, {
        ...hasInsuranceCondition
      }),
      fieldPlanName(insuranceFields.pharmacyName, schema?.[insuranceFields.pharmacyName], {
        sizes: { md: 4 },
        validate: [
          {
            type: 'name'
          }
        ],
        ...hasInsuranceCondition
      }),
      fieldTextSection(insuranceFields.pharmacyCardholderId, schema?.[insuranceFields.pharmacyCardholderId], {
        sizes: { md: 3 },
        validate: [
          {
            type: 'id',
            strict: true
          }
        ],
        ...cardholderIdConditions(insuranceFields.pharmacyName, true)
      }),
      fieldTextSection(insuranceFields.pharmacyRxGroupNumber, schema?.[insuranceFields.pharmacyRxGroupNumber], {
        sizes: { md: 2 },
        validate: [
          {
            type: 'id',
            strict: true
          }
        ],
        ...cardholderIdConditions(insuranceFields.pharmacyName, true)
      }),
      fieldTextSection(insuranceFields.pharmacyCardholderId, schema?.[insuranceFields.pharmacyCardholderIdOpt], {
        sizes: { md: 3 },
        validate: [
          {
            type: 'id',
            strict: true
          }
        ],
        ...cardholderIdConditions(insuranceFields.pharmacyName, false)
      }),
      fieldTextSection(insuranceFields.pharmacyRxGroupNumber, schema?.[insuranceFields.pharmacyRxGroupNumberOpt], {
        sizes: { md: 2 },
        validate: [
          {
            type: 'id',
            strict: true
          }
        ],
        ...cardholderIdConditions(insuranceFields.pharmacyName, false)
      }),
      spacerSection('insurance_10', { sizes: { sm: 12 }, ...hasInsuranceCondition }),
      fieldTextSection(insuranceFields.pharmacyRxBinIin, schema?.[insuranceFields.pharmacyRxBinIin], {
        sizes: { md: 2 },
        validate: [
          {
            type: 'id',
            strict: true,
            max: 8
          }
        ],
        ...hasInsuranceCondition
      }),
      fieldTextSection(insuranceFields.pharmacyRxPcn, schema?.[insuranceFields.pharmacyRxPcn], {
        sizes: { md: 2 },
        validate: [
          {
            type: 'id',
            strict: true
          }
        ],
        ...hasInsuranceCondition
      }),
      fieldTextSection(insuranceFields.pharmacyPhoneNumber, schema?.[insuranceFields.pharmacyPhoneNumber], {
        type: 'tel',
        sizes: { md: 3 },
        validate: [
          {
            type: 'phone'
          }
        ],
        ...hasInsuranceCondition
      }),
      spacerSection('insurance_11', { sizes: { sm: 12 }, ...hasInsuranceCondition }),
      headerSection(schema?.other_insurance_header?.label, schema?.other_insurance_header, {
        ...hasInsuranceCondition
      }),
      fieldTextSection(insuranceFields.otherPlanName, schema?.[insuranceFields.otherPlanName], {
        sizes: { md: 4 },
        validate: [
          {
            type: 'name'
          }
        ],
        ...hasInsuranceCondition
      }),
      fieldTextSection(insuranceFields.otherPlanCardholderId, schema?.[insuranceFields.otherPlanCardholderId], {
        sizes: { md: 3 },
        validate: [
          {
            type: 'id',
            strict: true
          }
        ],
        ...hasInsuranceCondition
      }),
      fieldTextSection(insuranceFields.otherPolicyHolderName, schema?.[insuranceFields.otherPolicyHolderName], {
        sizes: { md: 4 },
        validate: [
          {
            type: 'id',
            strict: true
          }
        ],
        ...hasInsuranceCondition
      }),
      fieldTextSection(insuranceFields.otherPlanGroupNumber, schema?.[insuranceFields.otherPlanGroupNumber], {
        sizes: { md: 2 },
        validate: [
          {
            type: 'id',
            strict: true
          }
        ],
        ...hasInsuranceCondition
      }),
      fieldTextSection(insuranceFields.otherPlanPhoneNumber, schema?.[insuranceFields.otherPlanPhoneNumber], {
        type: 'tel',
        sizes: { md: 3 },
        validate: [
          {
            type: 'phone'
          }
        ],
        ...hasInsuranceCondition
      }),
      spacerSection('insurance_12', { sizes: { sm: 12 }, ...hasInsuranceCondition }),
      headerSection(schema?.upload_insurance_cards_header?.label, schema?.upload_insurance_cards_header),
      fieldUploadSection(insuranceFields.cardUpload, schema?.[insuranceFields.cardUpload], {
        sizes: { md: 12 },
        dropzoneOptions: {},
        showRequiredText: schema?.[insuranceFields.cardUpload].showRequiredText,
        ...hasInsuranceCondition
      }),
      fieldUploadSection(insuranceFields.cardUpload, schema?.[insuranceFields.cardUploadOpt], {
        sizes: { md: 12 },
        dropzoneOptions: {},
        ...hasNoInsuranceCondition
      }),
      spacerSection('insurance_13', { sizes: { sm: 12 }, ...hasInsuranceCondition })
    ].filter(Boolean)
  };
};

export const hasInsuranceCondition: { condition: ConditionDefinition } = {
  condition: {
    when: patientFields.hasInsurance,
    is: 'yes'
  }
};

export const hasNoInsuranceCondition: { condition: ConditionDefinition } = {
  condition: {
    when: patientFields.hasInsurance,
    is: (value: any) => {
      if (!value || value === 'no') return true;
      return false;
    }
  }
};

export const hasInsuranceAndSupplementPlan: { condition: ConditionDefinition[] } = {
  condition: [
    {
      when: patientFields.hasInsurance,
      is: 'yes'
    },
    {
      when: insuranceFields.secondaryIsMedicalSupplementPlan,
      is: 'Y'
    }
  ]
};

// Condition for card holder id and group number optional fields for primary insurance
export const cardHolderAndGroupNumberOptionalCondition: { condition: ConditionDefinition[] } = {
  condition: [
    {
      when: patientFields.hasInsurance,
      is: 'yes'
    },
    {
      when: insuranceFields.primaryPlanPayerName,
      is: (value: any) => {
        return value && value[0]?.label === 'Medicare';
      }
    }
  ]
};

// Condition for card holder id and group number for primary insurance
export const cardHolderAndGroupNumberCondition: { condition: ConditionDefinition[] } = {
  condition: [
    {
      when: patientFields.hasInsurance,
      is: 'yes'
    },
    {
      when: insuranceFields.primaryPlanPayerName,
      is: (value: any) => {
        if (!value) return true;
        return value && value[0]?.label !== 'Medicare';
      }
    }
  ]
};

export const hasInsuranceConditionAndIsPrimaryCardHolder = (
  primaryCardholderFieldName: string,
  reqValue: string,
  show = false
): { condition: ConditionDefinition[] } => {
  if (show) {
    return {
      condition: [
        {
          when: patientFields.hasInsurance,
          is: 'yes'
        }
      ]
    };
  }
  return {
    condition: [
      {
        when: patientFields.hasInsurance,
        is: 'yes'
      },
      {
        when: primaryCardholderFieldName,
        is: reqValue
      }
    ]
  };
};

// Condition for subscriber relationship to card holder and subscriber DOB for secondary insurance
export const secondaryFieldEnableCondition = (
  payerNameFieldName: string | null,
  isPayerNameNotEmpty: boolean
): { condition: ConditionDefinition[] } => {
  const condition: ConditionDefinition[] = [
    {
      when: patientFields.hasInsurance,
      is: 'yes'
    }
  ];
  if (payerNameFieldName) {
    condition.push({
      when: payerNameFieldName,
      is: (value: any) => {
        return isPayerNameNotEmpty ? value && value.length > 0 : !value || (value && value.length <= 0);
      }
    });
  }
  return {
    condition: condition
  };
};

export const cardholderIdConditions = (
  payerNameFieldName: string | null,
  isPayerNameNotEmpty: boolean
): { condition: ConditionDefinition[] } => {
  const condition: ConditionDefinition[] = [
    {
      when: patientFields.hasInsurance,
      is: 'yes'
    }
  ];
  if (payerNameFieldName) {
    condition.push({
      when: payerNameFieldName,
      is: (value: any) => {
        return isPayerNameNotEmpty
          ? value && value.length > 0 && value[0]?.label !== 'Medicare'
          : value && value[0]?.label === 'Medicare'
          ? true
          : !value || (value && value.length <= 0);
      }
    });
  }
  return {
    condition: condition
  };
};
