import React from 'react';

import { useSelector } from 'react-redux';

import flags from '../hooks/flags';
import { AnyType, HcpSelectProps } from '../interfaces';
import { AppRootState, tenancyFeatureSelector } from '../redux';
import { SelectField } from './';

export const ProductsSelect = (props: HcpSelectProps) => {
  let productsList: AnyType = useSelector((state: AppRootState) => state?.app?.entities?.drugList) || [];
  productsList = productsList.map((product: any) => {
    const productObject = {
      label: product.DrugBrandName,
      value: product.DrugBrandName
    };
    return productObject;
  });

  return <SelectField {...props} name={props.name} options={productsList ?? []} required={true} />;
};

export const SelfServiceRequestSelect = (props: HcpSelectProps) => {
  let { options } = props;
  options = options.map((option: any) => {
    return {
      label: option?.requestID,
      value: `${option?.requestID},${option?.requestType},${option?.requestStartDate}`
    };
  });

  return (
    <SelectField
      {...props}
      name={props.name}
      options={options ?? []}
      onBlur={(e: any) => {
        props?.onEserviceSelect(e?.target?.value?.split(',')?.[0]);
      }}
    />
  );
};

export const MessageSubjectsSelect = (props: HcpSelectProps) => {
  const messageSubjects: AnyType = props?.isGISrNeeded
    ? useSelector<AnyType>(tenancyFeatureSelector('secure-message-gi-sr.subject'))
    : useSelector<AnyType>(tenancyFeatureSelector('secure-message.subject'));
  return <SelectField {...props} name={props.name} options={messageSubjects ?? []} />;
};

export const StatesSelect = (props: HcpSelectProps) => {
  const states = flags.useStates();
  return <SelectField {...props} name={props.name} options={states} />;
};

export const SuffixSelect = (props: HcpSelectProps) => {
  const suffix = useSelector((state: AppRootState) => state.app.suffix);
  return <SelectField {...props} name={props.name} options={suffix ?? []} />;
};
