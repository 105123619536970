import { Maybe } from '../../graphql';
import { AnyType } from '../../interfaces';

export type PatientContactInfo = {
  patient?: Maybe<string>;
  caregiver?: Maybe<string>;
};

export type OptionType = {
  label: string;
  value: string;
};

export type ConsentModeOptionType = { label: string; value: EmailSmsConsentMode; contactPerson: string };

export interface FilePreview {
  bytes: string | ArrayBuffer | null;
  path: string;
  preview: string;
  date: string;
  size: number;
}

export enum ConsentStatusEnum {
  PENDING = 'pending',
  INVITE_INPROGRESS = 'inviteInProgress',
  CHECK_INPROGRESS = 'checkInProgress',
  INVITESENT = 'inviteSent',
  SUCCESS = 'success'
}

export enum EmailSmsConsentMode {
  DEFAULT = '',
  PATIENT_MOBILE = 'patientMobile',
  CAREGIVER_MOBILE = 'caregiverMobile',
  PATIENT_EMAIL = 'patientEmail',
  CAREGIVER_EMAIL = 'caregiverEmail'
}

export enum ConsentType {
  DEFAULT = '',
  EMAIL = 'Email',
  SMS = 'Sms',
  UPLOAD = 'upload',
  ONSITE = 'VerificationCode'
}

export enum ConsentFlow {
  REQUEST = 'request',
  UPDATE = 'update'
}
