import React, { useCallback, useContext, useMemo, useState } from 'react';
import Form from 'react-bootstrap/Form';
import { AnyType } from '../../interfaces';
import { CONSENT_MODAL_ERROR_MSG, CONSENT_MODAL_LABEL, REQUEST_CONSENT_OPTIONS } from '../../utils';
import { PatientProfileContext } from '../patient-profile-context';
import './ConsentModal.scss';
import EmailSmsConsent from './EmailSmsConsent';
import OnsiteConsent from './OnsiteConsent';
import UploadConsent from './UploadConsent';
import { ConsentContextProvider } from './context';
import { ConsentFlow, ConsentStatusEnum, ConsentType, EmailSmsConsentMode, PatientContactInfo } from './types';
import { checkConsentStatusByDrug, hasConsentOption, inviteEhipaa } from './utils';
import { useSelector } from 'react-redux';
import { AppRootState } from '../../redux';

type ConsentModalProps = {
  patientInfo: AnyType;
  programId: string;
  flow: ConsentFlow;
  onClose: () => void;
};

const CHECK_STATUS_ENABLED = false;

export default function ConsentModal(props: ConsentModalProps) {
  const { patientInfo, programId, flow, onClose } = props;
  const { selectedRequestInfo } = useContext(PatientProfileContext);
  const [consentType, setConsentType] = useState<ConsentType>(ConsentType.DEFAULT);
  const [emailSmsConsentMode, setEmailSmsConsentMode] = useState<EmailSmsConsentMode>(EmailSmsConsentMode.DEFAULT);
  const [consentStatus, setConsentStatus] = useState<ConsentStatusEnum>(ConsentStatusEnum.PENDING);
  const [channelValue, setChannelValue] = useState('');
  const [verificationCode, setVerificationCode] = useState('');
  const [isResend, setIsResend] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const allDrugs = useSelector(
    (state: AppRootState) =>
      (
        ((state.app.entities.features?.['consent-requested-drug']?.data as unknown) as { drugs: AnyType[] })?.drugs ??
        []
      ).find((item) => item.id === 'all-pah-drugs')?.value
  );

  const patientContactInfo: Record<string, PatientContactInfo> = useMemo(() => {
    const { phoneNumbers = [], patientContact = [], email = '' } = patientInfo;
    const patientPhoneNumber = phoneNumbers.find((item: any) => {
      return item.phoneType === 'mobile';
    })?.phoneNumber;

    return {
      Email: {
        patient: email,
        caregiver: patientContact?.[0]?.contact?.email
      },
      Sms: {
        patient: patientPhoneNumber,
        caregiver: undefined // TODO caregiver mobile number not stored patient?.patientContact?.[0]?.contact
      }
    };
  }, [patientInfo]);

  const CONSENT_TYPE_COMPONENT_MAP = {
    [ConsentType.DEFAULT]: <></>,
    [ConsentType.EMAIL]: <EmailSmsConsent type={ConsentType.EMAIL} />,
    [ConsentType.SMS]: <EmailSmsConsent type={ConsentType.SMS} />,
    [ConsentType.UPLOAD]: <UploadConsent />,
    [ConsentType.ONSITE]: <OnsiteConsent />
  };

  const filteredRequestOptions = useMemo(
    () => REQUEST_CONSENT_OPTIONS.filter((item) => hasConsentOption(item.value, programId)),
    []
  );

  const isOnsiteVerification = useMemo(() => consentType === ConsentType.ONSITE, [consentType]);

  const drugName = useMemo(() => {
    return selectedRequestInfo?.drugName ?? '';
  }, [selectedRequestInfo?.drugName]);

  const showCheckStatus = useMemo(
    () =>
      CHECK_STATUS_ENABLED &&
      flow === ConsentFlow.REQUEST &&
      [ConsentStatusEnum.CHECK_INPROGRESS, ConsentStatusEnum.INVITESENT].some((item) => item === consentStatus),
    [consentStatus]
  );

  const resetToDefaults = useCallback(() => {
    setEmailSmsConsentMode(EmailSmsConsentMode.DEFAULT);
    setChannelValue('');
    setVerificationCode('');
    if (consentStatus !== ConsentStatusEnum.SUCCESS) {
      setConsentStatus(ConsentStatusEnum.PENDING);
    }
  }, [consentStatus]);

  const displayError = useCallback((errorMessage) => {
    setErrorMessage(errorMessage);
    setTimeout(() => {
      setErrorMessage('');
    }, 3000);
  }, []);

  const isConsentOptionDisabled = (consentType: ConsentType) => {
    if ([ConsentType.EMAIL, ConsentType.SMS].some((item) => item === consentType)) {
      const { patient, caregiver } = patientContactInfo[consentType];
      return !patient && !caregiver;
    } else {
      return false;
    }
  };

  const sendInvite = async (currentChannel?: ConsentType) => {
    setIsResend(consentStatus === ConsentStatusEnum.INVITESENT);
    setConsentStatus(ConsentStatusEnum.INVITE_INPROGRESS);

    try {
      const inviteResponse = await inviteEhipaa(
        patientInfo,
        consentType,
        patientContactInfo,
        channelValue,
        drugName,
        allDrugs,
        currentChannel
      );
      setConsentStatus(ConsentStatusEnum.INVITESENT);
      if (isOnsiteVerification) {
        setVerificationCode(inviteResponse.verificationCode);
      }
    } catch (e) {
      displayError(CONSENT_MODAL_ERROR_MSG.GENERIC_ERROR);
      setConsentStatus(isResend ? ConsentStatusEnum.INVITESENT : ConsentStatusEnum.PENDING);
    }
  };

  const checkConsentStatus = async () => {
    setConsentStatus(ConsentStatusEnum.CHECK_INPROGRESS);
    try {
      const checkStatusResponse = await checkConsentStatusByDrug(patientInfo, drugName, allDrugs);
      setConsentStatus(checkStatusResponse ? ConsentStatusEnum.SUCCESS : ConsentStatusEnum.INVITESENT);
    } catch (e) {
      displayError(CONSENT_MODAL_ERROR_MSG.GENERIC_ERROR);
      setConsentStatus(ConsentStatusEnum.INVITESENT);
    }
  };
  return (
    <ConsentContextProvider
      value={{
        errorMessage,
        flow,
        showCheckStatus,
        verificationCode,
        consentType,
        patientContactInfo,
        consentStatus,
        channelValue,
        updateChannelValue: setChannelValue,
        updateConsentStatus: setConsentStatus,
        consentMode: emailSmsConsentMode,
        sendInvite,
        updateEmailSmsConsentMode: setEmailSmsConsentMode,
        checkConsentStatus
      }}>
      <section className='cmx__request-consent-modal'>
        <h4 className='cmx__request-consent-modal__h4-title'>
          {flow === ConsentFlow.UPDATE
            ? CONSENT_MODAL_LABEL.UPDATE_CONSENT_HEADER
            : CONSENT_MODAL_LABEL.REQUEST_CONSENT_HEADER}
        </h4>
        <hr className='cmx__request-consent-modal__hr-separator' />
        <div className='d-flex flex-column cmx__request-consent-modal__div-content'>
          <label className='cmx__request_consent-modal__label-select-consent-type'>
            {CONSENT_MODAL_LABEL.SELECT_CONSENT_TYPE}
          </label>
          <div className='d-flex cmx__request_consent-modal__div-request-options'>
            {filteredRequestOptions.map((item) => {
              const isOptionDisabled = isConsentOptionDisabled(item.value);
              return (
                <Form.Check
                  className={`d-flex align-items-center cmx__request_consent-modal__Check-option ${
                    isOptionDisabled ? 'disabled' : ''
                  }`}
                  disabled={isOptionDisabled}
                  key={item.value}
                  checked={consentType === item.value}
                  type='radio'
                  id={`option-${item.value}`}
                  label={item.label}
                  onChange={() => {
                    setConsentType(item.value);
                    resetToDefaults();
                  }}
                />
              );
            })}
          </div>
          {CONSENT_TYPE_COMPONENT_MAP[consentType]}
        </div>
        <hr className='cmx__request-consent-modal__hr-separator' />
        <div className='d-flex justify-content-end my-3'>
          <button className='cmx__request_consent-modal__button-close' onClick={onClose}>
            {CONSENT_MODAL_LABEL.CLOSE}
          </button>
        </div>
      </section>
    </ConsentContextProvider>
  );
}
