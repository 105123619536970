/* Generated by restful-react */

import React from 'react';
import { Get, GetProps, useGet, UseGetProps, Mutate, MutateProps, useMutate, UseMutateProps } from 'restful-react';
export const SPEC_VERSION = 'v1';
export interface CreateConnectUserRequest {
  applicationName?: string | null;
  userName?: string | null;
  emailAddress?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  userType?: string | null;
  userRole?: string | null;
  npi?: string | null;
  organizationId?: number;
  programId?: number;
  caremetxRecordId?: number;
  caremetxRecordType?: string | null;
  faxNumber?: string | null;
}

export interface Error {
  code?: string | null;
  description?: string | null;
}

export interface CreateUserResponse {
  errors?: Error[] | null;
  userId?: string | null;
  status?: string | null;
  resetToken?: string | null;
}

export interface UpdateUserResponse {
  errors?: Error[] | null;
  isSuccess?: boolean | null;
}

export interface UpdateAdminRequest {
  applicationName?: string | null;
  userName?: string | null;
  isAdmin?: boolean;
}

export interface UpdatePasswordResponse {
  errors?: Error[] | null;
  status?: string | null;
}

export interface SetUserStateConnectRequest {
  applicationName?: string | null;
  userName?: string | null;
  activate?: boolean;
  organizationId?: number;
  programId?: number;
}

export interface SetUserStateResponse {
  errors?: Error[] | null;
}

export interface User {
  firstName?: string | null;
  lastName?: string | null;
  cellNumber?: string | null;
  userName?: string | null;
  userType?: string | null;
  email?: string | null;
  isActive?: boolean | null;
  isAdmin?: boolean | null;
  careMetxRecordId?: number;
  careMetxRecordType?: string | null;
  created?: string;
  organizationId?: number;
  role?: string | null;
  programid?: number;
}

export interface GetUsersResponse {
  errors?: Error[] | null;
  users?: User[] | null;
}

export interface ResetPasswordResponse {
  errors?: Error[] | null;
  resetPasswordToken?: string | null;
}

export interface DocumentModel {
  fileData?: string | null;
  fileName?: string | null;
}

export interface GetDocumentResponse {
  errors?: Error[] | null;
  document?: DocumentModel;
}

export interface UploadDocumentModel {
  serviceRequestId?: number;
  entityName?: string | null;
  documentSource?: string | null;
  createdBy?: string | null;
  documentType?: string | null;
  lookupDataListFunctionName?: string | null;
  document?: DocumentModel;
}

export interface LoginUserRequest {
  userName?: string | null;
  password?: string | null;
  applicationName?: string | null;
}

export interface OrganizationAddressRequest {
  organizationAddressId?: number | null;
  addressType?: string | null;
  address1?: string | null;
  address2?: string | null;
  city?: string | null;
  state?: string | null;
  zip?: string | null;
  phone1?: string | null;
  phone1Type?: string | null;
  fax?: string | null;
  faxComments?: string | null;
  prescriberId?: number | null;
}

export interface LoginUserResponse {
  errors?: Error[] | null;
  loginResponse?: LoginResponse;
}

export interface LoginResponse {
  expiresAt?: string | null;
  status?: string | null;
  sessionToken?: string | null;
  _Embedded?: Embedded;
  _links?: Links;
}

export interface Embedded {
  user?: UserData;
}

export interface Hints {
  allow?: string[] | null;
}

export interface LinkCancel {
  href?: string | null;
  hints?: Hints;
}

export interface Links {
  cancel?: LinkCancel;
}

export interface UserData {
  id?: string | null;
  passwordChanged?: string | null;
  profile?: UserProfile;
}

export interface UserProfile {
  login?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  locale?: string | null;
  timeZone?: string | null;
}

export interface OrganizationAddressResponse {
  errors?: Error[] | null;
  organizationAddressId?: number | null;
}

export interface LinkPatientRequest {
  patientId?: number;
}

export interface LinkPatientResponse {
  errors?: Error[] | null;
  patientOrganizationId?: number | null;
}

export interface OrganizationAddress {
  organizationAddressId?: number | null;
  address1?: string | null;
  address2?: string | null;
  zip?: string | null;
  city?: string | null;
  state?: string | null;
  phoneNumber?: string | null;
  faxNumber?: string | null;
}

export interface Organization {
  organizationId?: number | null;
  name?: string | null;
  npi?: string | null;
  taxId?: string | null;
  organizationAddress?: OrganizationAddress;
}

export interface OfficeContact {
  lastName?: string | null;
  firstName?: string | null;
  phoneNumber?: string | null;
  fax?: string | null;
  eMail?: string | null;
}

export interface Physician {
  physicianId?: number | null;
  lastName?: string | null;
  firstName?: string | null;
  middleName?: string | null;
  npi?: string | null;
  taxId?: string | null;
  stateLicenseNumber?: string | null;
  licenseState?: string | null;
  organization?: Organization;
  officeContact?: OfficeContact;
  ptan?: string | null;
  dea?: string | null;
  email?: string | null;
  hin?: string | null;
}

export interface CreatePhysicianResponse {
  errors?: Error[] | null;
  physicianId?: number | null;
}

export interface MessageThreadViewModel {
  threadID?: number;
  therapy?: string | null;
  source?: string | null;
  subject?: string | null;
  messageText?: string | null;
  lastMessageSource?: string | null;
  sender?: string | null;
  isRead?: boolean | null;
  status?: string | null;
  assignedTo?: string | null;
  created?: string;
  lastMessageDate?: string | null;
  organizationName?: string | null;
  providerName?: string | null;
  patientName?: string | null;
  patientDOB?: string | null;
  patientID?: number | null;
  patientFirstName?: string | null;
  patientLastName?: string | null;
}

export interface GetMessageThreadResponse {
  errors?: Error[] | null;
  messageThreads?: MessageThreadViewModel[] | null;
}

export interface MessageFile {
  messageID?: number | null;
  fileName?: string | null;
  fileType?: string | null;
  documentId?: number;
  fileData?: string | null;
}

export interface Message {
  threadID?: number | null;
  messageID?: number;
  source?: string | null;
  sender?: string | null;
  messageDate?: string | null;
  messageText?: string | null;
  providerID?: number | null;
  providerName?: string | null;
  patientID?: number | null;
  patientName?: string | null;
  isSentFromCurrentUser?: boolean;
  isRead?: boolean;
  patientExternalId?: string | null;
  patientDOB?: string | null;
  patientFirstName?: string | null;
  patientLastName?: string | null;
  files?: MessageFile[] | null;
}

export interface MessageThread {
  threadID?: number;
  therapy?: string | null;
  source?: string | null;
  subject?: string | null;
  messageText?: string | null;
  sender?: string | null;
  isRead?: boolean;
  status?: string | null;
  assignedTo?: string | null;
  created?: string;
  lastMessageDate?: string | null;
  organizationId?: number;
  organizationName?: string | null;
  providerName?: string | null;
  sendToApplicationId?: string;
  patientName?: string | null;
  patientId?: number | null;
  patientFirstName?: string | null;
  patientLastName?: string | null;
  serviceRequestID?: number | null;
  messages?: Message[] | null;
  patientDOB?: string | null;
  providerId?: number | null;
  sR_Status?: string | null;
}

export interface MessageThreadDetailsResponse {
  errors?: Error[] | null;
  messageThread?: MessageThread;
}

export interface UnReadMessageThreadResponse {
  errors?: Error[] | null;
  count?: number;
}

export interface ComposeMessageRequest {
  threadId?: number | null;
  subject?: string | null;
  userName?: string | null;
  patientId?: number;
  providerId?: number | null;
  messageText?: string | null;
  serviceRequestId?: number | null;
  applicationName?: string | null;
  files?: DocumentModel[] | null;
  transactionId?: string | null;
  transactionType?: string | null;
  transactionDate?: string | null;
  sRDrugName?: string | null;
  physicianNPI?: string | null;
}

export interface MessageIDsViewModel {
  threadID?: number;
  messageID?: number;
}

export interface ComposeMessageResponse {
  errors?: Error[] | null;
  messageIDs?: MessageIDsViewModel;
}

export interface PatientPhoneNumbers {
  patientPhoneNumberId?: number | null;
  phoneNumber?: string | null;
  phoneType?: string | null;
  okToLeaveMessage?: boolean;
}

export interface Caregiver {
  firstName?: string | null;
  middleName?: string | null;
  lastName?: string | null;
  email?: string | null;
  homePhoneNumber?: string | null;
  mobilePhoneNumber?: string | null;
  relationship?: string | null;
  isLegallyAuthorizedRepresentative?: boolean;
}

export interface Provider {
  pharmacyName?: string | null;
  otherValue?: string | null;
}

export interface Patient {
  patientId?: number | null;
  firstName?: string | null;
  middleName?: string | null;
  lastName?: string | null;
  dob?: string | null;
  gender?: string | null;
  preferredLanguage?: string | null;
  otherLanguage?: string | null;
  email?: string | null;
  last4SSN?: string | null;
  preferredPhone?: string | null;
  patientPhoneNumbers?: PatientPhoneNumbers[] | null;
  okToLeaveMessageMobile?: boolean | null;
  okToLeaveMessageHome?: boolean | null;
  patientAddressId?: number | null;
  address1?: string | null;
  address2?: string | null;
  zip?: string | null;
  city?: string | null;
  state?: string | null;
  hasCaregiver?: boolean | null;
  caregiver?: Caregiver;
}

export interface PatientInsurance {
  patientInsuranceId?: number | null;
  insuranceType?: string | null;
  medicalCardHolderId?: string | null;
  medicalGroup?: string | null;
  medicalPhoneNumber?: string | null;
  medicalPlanName?: string | null;
  pharmacyBINNumber?: string | null;
  pharmacyCardholderID?: string | null;
  pharmacyGroup?: string | null;
  pharmacyPCN?: string | null;
  pharmacyPhoneNumber?: string | null;
  pharmacyPlanName?: string | null;
  policyHolderName?: string | null;
  isPatientPrimaryCardholder?: boolean;
  subscriberFirstName?: string | null;
  subscriberLastName?: string | null;
  subscriberDateBirth?: string | null;
  employer?: string | null;
  subscriberRelationshipToCardholder?: string | null;
  isMedicalSupplementPlan?: boolean | null;
  medicalSupplementPlanLetter?: string | null;
}

export interface Insurance {
  hasPatientInsurance?: boolean;
  medicarePartB?: boolean | null;
  medicarePartD?: boolean | null;
  medicareAdvantage?: boolean | null;
  medicaid?: boolean | null;
  medicaidPending?: boolean | null;
  military?: boolean | null;
  privateInsurance?: boolean | null;
  other?: boolean | null;
  otherInsurance?: string | null;
  medicareBeneficiaryId?: string | null;
  insuranceBenefitPlanName?: string | null;
  patientInsurances?: PatientInsurance[] | null;
  insuranceCard?: DocumentModel[] | null;
}

export interface TreatingSite {
  referMyPatient?: boolean | null;
  sendUpdateCommunications?: string[] | null;
  name?: string | null;
  contactName?: string | null;
  phone?: string | null;
  fax?: string | null;
  email?: string | null;
  npi?: string | null;
  taxId?: string | null;
}

export interface CPTCode {
  code1?: string | null;
  code2?: string | null;
  code3?: string | null;
  code4?: string | null;
  code5?: string | null;
}

export interface PreviousTreatment {
  treatmentType?: string | null;
  startDate?: string | null;
  endDate?: string | null;
  dose?: string | null;
  treatment?: string | null;
  frequency?: string | null;
}

export interface Diagnosis {
  treatmentStartDate?: string | null;
  treatmentLastDate?: string | null;
  diagnosisCode?: string | null;
  diagnosisDescription?: string | null;
  otherDiagnosisCode?: string | null;
  patientPreviouslyEnrolled?: boolean;
  hasASCVSorHeFH?: boolean | null;
  cptCode?: CPTCode;
  billingPreference?: string | null;
  isSampleProductAdministered?: boolean | null;
  dateProductNeeded?: string | null;
  anyKnownDrugAllergies?: boolean | null;
  allergies?: string | null;
  contraindications?: string | null;
  visualAcuityRightEye?: string | null;
  visualAcuityLeftEye?: string | null;
  hasStartedTreatment?: boolean | null;
  diagnosisCodesSecondary?: string | null;
  diagnosisCodesTertiary?: string | null;
  previousTreatments?: PreviousTreatment[] | null;
  optIn?: boolean | null;
  clinicalPatientMeets?: string[] | null;
  hasASCVD?: boolean | null;
  hasHeFH?: boolean | null;
  hasClinicalOther?: boolean | null;
  clinicalASCVDText?: string | null;
  clinicalHeFHText?: string[] | null;
  clinicalOtherText?: string | null;
}

export interface Other {
  patientCopayId?: string | null;
  groupNumber?: string | null;
  preferredMethodOfPayment?: string | null;
  additionalDocuments?: DocumentModel[] | null;
  organizationEmail?: string | null;
  checkPayableTo?: string | null;
  copayProviderPaymentRemittanceAddress?: string | null;
  copayCity?: string | null;
  copayState?: string | null;
  copayZipCode?: string | null;
  copayFaxNumber?: string | null;
  copayNpiNumber?: string | null;
  copayTaxIdNumber?: string | null;
  copayReimbursementCity?: string | null;
  copayReimbursementState?: string | null;
  copayReimbursementZip?: string | null;
  copayMedicationName?: string | null;
  copayRegistrationRepayment?: string | null;
  copayPaymentReimbursementAddress?: string | null;
  copaySuite?: string | null;
  copayPrimaryPayerName?: string | null;
  copayDateOfService?: string | null;
  copayPatientCostShareEOD?: string | null;
  copayReimbursementAmountRequested?: number | null;
  copayRequestorFirstname?: string | null;
  copayRequestorLastName?: string | null;
  copayRequestorPhoneNumber?: string | null;
  copayRequestorEmailAddress?: string | null;
}

export interface Facility {
  facilityId?: number | null;
  npi?: string | null;
  name?: string | null;
  address1?: string | null;
  address2?: string | null;
  state?: string | null;
  city?: string | null;
  zip?: string | null;
  taxId?: string | null;
  officeContactFirstName?: string | null;
  officeContactLastName?: string | null;
  officeContactPhone?: string | null;
}

export interface ElectronicAttestation {
  patientEmail?: string | null;
  patientMobileNumber?: string | null;
}

export interface PatientAttestation {
  patientAttestationId?: number | null;
  attestationType?: string | null;
  manualAttestationDocument?: DocumentModel;
  electronicAttestation?: ElectronicAttestation;
}

export interface PrescriberAttestation {
  isCertified?: boolean;
  isCertified2?: boolean;
  isCertified3?: boolean;
  typedSignature?: string | null;
  signatureDate?: string | null;
  contactPatientForEnrollmentIssues?: boolean;
}

export interface Prescription {
  drugDescription?: string | null;
  quantity?: number | null;
  quantityUnitOfMeasure?: string | null;
  daysSupply?: number | null;
  numberOfRefills?: number | null;
  effectiveDate?: string | null;
  allowSubstitutions?: boolean | null;
  numberOfPeriodontalSites?: number | null;
  directionForUse?: string | null;
  additionalNotes?: string | null;
  height?: string | null;
  weight?: string | null;
  bsa?: string | null;
  mg15?: string | null;
  mg20?: string | null;
  fixedDoseTablet?: string | null;
  tabletsPerCycle?: string | null;
  sig?: string | null;
  sigAlternate?: string | null;
  typedSignature?: string | null;
  typedSignatureDate?: string | null;
  numberOfRefillsAnother?: number | null;
  tabletsPerCycleAnother?: string | null;
  initialDose?: string | null;
  secondDose?: string | null;
  physicianSignatureType?: string | null;
  signingAttestation?: boolean | null;
}

export interface Signature {
  entityType: string;
  signatureType?: string | null;
  signaturePurpose: string;
  signatureDateTime?: string | null;
  signatureImage?: string | null;
}

export interface Document {
  fileData?: string | null;
  fileName?: string | null;
}
export interface SupportService {
  serviceName?: string | null;
  value?: boolean;
}
export interface EnrollmentRequest {
  userName?: string | null;
  enrollmentType?: string | null;
  enrollmentSubType?: string | null;
  patient?: Patient;
  insurance?: Insurance;
  physicians?: Physician;
  diagnosis?: Diagnosis;
  administrationInformation?: string | null;
  other?: Other;
  facility?: Facility;
  administrationInformationForms?: DocumentModel;
  treatingSite?: TreatingSite;
  patientAttestation?: PatientAttestation;
  prescriberAttestation?: PrescriberAttestation;
  prescription?: Prescription;
  placeOfService?: string | null;
  enrollmentSignatures?: Signature[] | null;
  drugId?: number | null;
  caseRecordId?: number | null;
  isEnrollmentUsingModule?: boolean;
  document?: Document;
  patientEnrollmentGuid?: string | null;
}

export interface EnrollmentResponse {
  errors?: Error[] | null;
  enrollmentId?: number | null;
  serviceRequestId?: number | null;
}

export interface UniqueEmailResponse {
  errors?: Error[] | null;
  isUniqueEmail?: boolean;
}

export interface UniqueUserNameResponse {
  errors?: Error[] | null;
  isUniqueUserName?: boolean;
}

export interface UpdateUserRequest {
  applicationName?: string | null;
  emailAddress?: string | null;
  cellNumber?: string | null;
  userName?: string | null;
}

export interface CreateUserRequest {
  applicationName?: string | null;
  userName?: string | null;
  emailAddress?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  userType?: string | null;
  userRole?: string | null;
  npi?: string | null;
  faxNumber?: string | null;
}

export interface ChangePasswordRequest {
  oldPassword?: string | null;
  newPassword?: string | null;
}

export interface ChangePasswordResponse {
  errors?: Error[] | null;
}

export interface VerifyResetTokenRequest {
  applicationName?: string | null;
  resetToken?: string | null;
}

export interface VerifyResetTokenResponse {
  errors?: Error[] | null;
  tokenStatus?: string | null;
}

export interface UpdatePasswordRequest {
  applicationName?: string | null;
  resetToken?: string | null;
  newPassword?: string | null;
}

export interface UpdateAdminResponse {
  errors?: Error[] | null;
  success?: boolean;
}

export interface SetUserStateRequest {
  applicationName?: string | null;
  userName?: string | null;
  activate?: boolean;
}

export interface ChannelDetails {
  id?: number | null;
  notificationChannelId?: number | null;
  channelName?: string | null;
  optedOut?: boolean;
  optedDate?: string | null;
}

export interface Preference {
  id?: number | null;
  notificationTypeId?: number;
  notificationName?: string | null;
  notificationChannelId?: number;
  channelName?: string | null;
  optedOut?: boolean;
  optedDate?: string;
}

export interface NotificationPreference {
  notificationTypeId?: number;
  notificationName?: string | null;
  channelDetails?: ChannelDetails[] | null;
}

export interface UserNotificationTypePreferences {
  notificationEmail?: string | null;
  notificationSMS?: string | null;
  userName?: string | null;
  applicationName?: string | null;
  preferences?: NotificationPreference[] | null;
}

export interface UserNotificationPreference {
  notificationEmail?: string | null;
  notificationSMS?: string | null;
  userName?: string | null;
  applicationName?: string | null;
  preferences?: Preference[] | null;
}

export interface NotificationPreferencesResponse {
  errors?: Error[] | null;
  userNotificationTypePreferences?: UserNotificationTypePreferences;
}

export interface PostUserNotificationPreferencesResponse {
  errors?: Error[] | null;
  isSuccess?: boolean;
}

export interface EnrollmentModuleSummaryResponse {
  errors?: Error[] | null;
  URL?: string;
}

export interface GetDocumentQueryParams {
  documentId?: number;
}
export interface RxAdminDose {
  patient: {
    id: number;
    patientFirstName?: string | null;
    patientLastName?: string | null;
    patientDob?: string | null;
  };
  drug: {
    id: number;
    name?: string | null;
  };
  adminDose?: number | null;
  adminDate?: string | null;
  adminStatus?: string | null;
  adminSource?: string | null;
  adminNotes?: string | null;
  physician: {
    id: number;
    npi?: string | null;
    name?: string | null;
  };
}

export interface CreateRxAdminDoseResponse {
  errors?: Error[] | null;
  status?: string | null;
}
export interface rxAdminDoseList {
  patient: {
    id: number | null;
    patientId: number | null;
    patientFirstName: string | null;
    patientLastName: string | null;
    patientDob: string | null;
  };
  programId: number | null;
  ndc: number | null;
  drugName: string | null;
  drugForm: string | null;
  drugStrength: string | null;
  adminDose: number | null;
  adminDate: string | null;
  adminStatus: string | null;
  adminSource: string | null;
  adminNotes: string | null;
  updatedDate: string | null;
  createdBy: string | null;
  updatedBy: string | null;
  createdDate: string | null;
  prescriber?: string | null;
  prescriberId: number;
  npi?: string | null;
}
export interface RxAdminDoseListResponse {
  errors?: Error[] | null;
  rxAdminDoseList?: rxAdminDoseList[];
}

export interface RxAdminCheckSRExistResponse {
  errors?: Error[] | null;
  patientSrExist?: boolean | null;
}
export interface RxAdminCheckStatusExistResponse {
  errors?: Error[] | null;
  rxAdminStatusExist?: boolean | null;
}

export interface RxAdminListQueryParams {
  patientId?: number | null;
}

export interface RxAdminCheckSRExistsQueryParams {
  patientId?: number | null;
}
export interface RxAdminCheckStatusExistQueryParams {
  patientId?: number | null;
}

export interface EditRxAdminDose {
  id: number;
  adminStatus: string;
  adminDate: string;
  adminNotes: string | null;
  physician: {
    id: number;
    npi?: string | null;
    name?: string | null;
  };
}

export interface EditRxAdminDoseResponse {
  errors?: Error[] | null;
  status?: string | null;
}

export type GetRxAdminDoseListsProps = Omit<
  GetProps<RxAdminDoseListResponse, unknown, RxAdminListQueryParams, void>,
  'path'
>;

export const GetRxAdminList = (props: GetRxAdminDoseListsProps) => (
  <Get<RxAdminDoseListResponse, unknown, RxAdminListQueryParams, void> path={`/api/RxAdmin/ViewDose`} {...props} />
);
export type UseRxAdminDoseListProps = Omit<
  UseGetProps<RxAdminDoseListResponse, unknown, RxAdminListQueryParams, void>,
  'path'
>;
export const useGetRxAdminList = (props: UseRxAdminDoseListProps) =>
  useGet<RxAdminDoseListResponse, unknown, RxAdminListQueryParams, void>(`/api/RxAdmin/ViewDose`, props);

export type GetDocumentProps = Omit<GetProps<GetDocumentResponse, unknown, GetDocumentQueryParams, void>, 'path'>;

export const GetDocument = (props: GetDocumentProps) => (
  <Get<GetDocumentResponse, unknown, GetDocumentQueryParams, void> path={`/api/Document/GetDocument`} {...props} />
);

export type UseGetDocumentProps = Omit<UseGetProps<GetDocumentResponse, unknown, GetDocumentQueryParams, void>, 'path'>;

export const useGetDocument = (props: UseGetDocumentProps) =>
  useGet<GetDocumentResponse, unknown, GetDocumentQueryParams, void>(`/api/Document/GetDocument`, props);

export type UploadDocumentProps = Omit<
  MutateProps<GetDocumentResponse, unknown, void, UploadDocumentModel, void>,
  'path' | 'verb'
>;

export const UploadDocument = (props: UploadDocumentProps) => (
  <Mutate<GetDocumentResponse, unknown, void, UploadDocumentModel, void>
    verb='POST'
    path={`/api/Document/UploadDocument`}
    {...props}
  />
);

export type UseUploadDocumentProps = Omit<
  UseMutateProps<GetDocumentResponse, unknown, void, UploadDocumentModel, void>,
  'path' | 'verb'
>;

export const useUploadDocument = (props: UseUploadDocumentProps) =>
  useMutate<GetDocumentResponse, unknown, void, UploadDocumentModel, void>(
    'POST',
    `/api/Document/UploadDocument`,
    props
  );

export type LoginUserProps = Omit<
  MutateProps<LoginUserResponse, unknown, void, LoginUserRequest, void>,
  'path' | 'verb'
>;

export const LoginUser = (props: LoginUserProps) => (
  <Mutate<LoginUserResponse, unknown, void, LoginUserRequest, void>
    verb='POST'
    path={`/api/Auth/LoginUser`}
    {...props}
  />
);

export type UseLoginUserProps = Omit<
  UseMutateProps<LoginUserResponse, unknown, void, LoginUserRequest, void>,
  'path' | 'verb'
>;

export const useLoginUser = (props: UseLoginUserProps) =>
  useMutate<LoginUserResponse, unknown, void, LoginUserRequest, void>('POST', `/api/Auth/LoginUser`, props);

export type CreateAddressProps = Omit<
  MutateProps<OrganizationAddressResponse, unknown, void, OrganizationAddressRequest, void>,
  'path' | 'verb'
>;

export const CreateAddress = (props: CreateAddressProps) => (
  <Mutate<OrganizationAddressResponse, unknown, void, OrganizationAddressRequest, void>
    verb='POST'
    path={`/api/Organization/CreateAddress`}
    {...props}
  />
);

export type UseCreateAddressProps = Omit<
  UseMutateProps<OrganizationAddressResponse, unknown, void, OrganizationAddressRequest, void>,
  'path' | 'verb'
>;

export const useCreateAddress = (props: UseCreateAddressProps) =>
  useMutate<OrganizationAddressResponse, unknown, void, OrganizationAddressRequest, void>(
    'POST',
    `/api/Organization/CreateAddress`,
    props
  );

export type LinkPatientProps = Omit<
  MutateProps<LinkPatientResponse, unknown, void, LinkPatientRequest, void>,
  'path' | 'verb'
>;

export const LinkPatient = (props: LinkPatientProps) => (
  <Mutate<LinkPatientResponse, unknown, void, LinkPatientRequest, void>
    verb='POST'
    path={`/api/Organization/LinkPatient`}
    {...props}
  />
);

export type UseLinkPatientProps = Omit<
  UseMutateProps<LinkPatientResponse, unknown, void, LinkPatientRequest, void>,
  'path' | 'verb'
>;

export const useLinkPatient = (props: UseLinkPatientProps) =>
  useMutate<LinkPatientResponse, unknown, void, LinkPatientRequest, void>(
    'POST',
    `/api/Organization/LinkPatient`,
    props
  );

export type CreatePhysicianProps = Omit<
  MutateProps<CreatePhysicianResponse, unknown, void, Physician, void>,
  'path' | 'verb'
>;

export const CreatePhysician = (props: CreatePhysicianProps) => (
  <Mutate<CreatePhysicianResponse, unknown, void, Physician, void>
    verb='POST'
    path={`/api/Physician/CreatePhysician`}
    {...props}
  />
);

export type UseCreatePhysicianProps = Omit<
  UseMutateProps<CreatePhysicianResponse, unknown, void, Physician, void>,
  'path' | 'verb'
>;

export const useCreatePhysician = (props: UseCreatePhysicianProps) =>
  useMutate<CreatePhysicianResponse, unknown, void, Physician, void>('POST', `/api/Physician/CreatePhysician`, props);

export interface ThreadsQueryParams {
  applicationName?: string | null;
}

export type ThreadsProps = Omit<GetProps<GetMessageThreadResponse, unknown, ThreadsQueryParams, void>, 'path'>;

export const Threads = (props: ThreadsProps) => (
  <Get<GetMessageThreadResponse, unknown, ThreadsQueryParams, void> path={`/api/SecureMessaging/Threads`} {...props} />
);

export type UseThreadsProps = Omit<UseGetProps<GetMessageThreadResponse, unknown, ThreadsQueryParams, void>, 'path'>;

export const useThreads = (props: UseThreadsProps) =>
  useGet<GetMessageThreadResponse, unknown, ThreadsQueryParams, void>(`/api/SecureMessaging/Threads`, props);

export interface ThreadDetailsQueryParams {
  applicationName?: string | null;
  threadId?: number;
}

export type ThreadDetailsProps = Omit<
  GetProps<MessageThreadDetailsResponse, unknown, ThreadDetailsQueryParams, void>,
  'path'
>;

export const ThreadDetails = (props: ThreadDetailsProps) => (
  <Get<MessageThreadDetailsResponse, unknown, ThreadDetailsQueryParams, void>
    path={`/api/SecureMessaging/ThreadDetails`}
    {...props}
  />
);

export type UseThreadDetailsProps = Omit<
  UseGetProps<MessageThreadDetailsResponse, unknown, ThreadDetailsQueryParams, void>,
  'path'
>;

export const useThreadDetails = (props: UseThreadDetailsProps) =>
  useGet<MessageThreadDetailsResponse, unknown, ThreadDetailsQueryParams, void>(
    `/api/SecureMessaging/ThreadDetails`,
    props
  );

export interface CaseThreadsQueryParams {
  applicationName?: string | null;
  caseRecordId?: number;
}

export type CaseThreadsProps = Omit<GetProps<GetMessageThreadResponse, unknown, CaseThreadsQueryParams, void>, 'path'>;

export const CaseThreads = (props: CaseThreadsProps) => (
  <Get<GetMessageThreadResponse, unknown, CaseThreadsQueryParams, void>
    path={`/api/SecureMessaging/CaseThreads`}
    {...props}
  />
);

export type UseCaseThreadsProps = Omit<
  UseGetProps<GetMessageThreadResponse, unknown, CaseThreadsQueryParams, void>,
  'path'
>;

export const useCaseThreads = (props: UseCaseThreadsProps) =>
  useGet<GetMessageThreadResponse, unknown, CaseThreadsQueryParams, void>(`/api/SecureMessaging/CaseThreads`, props);

export interface UnreadMessageCountQueryParams {
  applicationName?: string | null;
}

export type UnreadMessageCountProps = Omit<
  GetProps<UnReadMessageThreadResponse, unknown, UnreadMessageCountQueryParams, void>,
  'path'
>;

export const UnreadMessageCount = (props: UnreadMessageCountProps) => (
  <Get<UnReadMessageThreadResponse, unknown, UnreadMessageCountQueryParams, void>
    path={`/api/SecureMessaging/UnreadMessageCount`}
    {...props}
  />
);

export type UseUnreadMessageCountProps = Omit<
  UseGetProps<UnReadMessageThreadResponse, unknown, UnreadMessageCountQueryParams, void>,
  'path'
>;

export const useUnreadMessageCount = (props: UseUnreadMessageCountProps) =>
  useGet<UnReadMessageThreadResponse, unknown, UnreadMessageCountQueryParams, void>(
    `/api/SecureMessaging/UnreadMessageCount`,
    props
  );

export interface UnreadCaseMessageCountQueryParams {
  applicationName?: string | null;
  caseRecordId?: number;
}

export type UnreadCaseMessageCountProps = Omit<
  GetProps<UnReadMessageThreadResponse, unknown, UnreadCaseMessageCountQueryParams, void>,
  'path'
>;

export const UnreadCaseMessageCount = (props: UnreadCaseMessageCountProps) => (
  <Get<UnReadMessageThreadResponse, unknown, UnreadCaseMessageCountQueryParams, void>
    path={`/api/SecureMessaging/UnreadCaseMessageCount`}
    {...props}
  />
);

export type UseUnreadCaseMessageCountProps = Omit<
  UseGetProps<UnReadMessageThreadResponse, unknown, UnreadCaseMessageCountQueryParams, void>,
  'path'
>;

export const useUnreadCaseMessageCount = (props: UseUnreadCaseMessageCountProps) =>
  useGet<UnReadMessageThreadResponse, unknown, UnreadCaseMessageCountQueryParams, void>(
    `/api/SecureMessaging/UnreadCaseMessageCount`,
    props
  );

export type ComposeMessageProps = Omit<
  MutateProps<ComposeMessageResponse, unknown, void, ComposeMessageRequest, void>,
  'path' | 'verb'
>;

export const ComposeMessage = (props: ComposeMessageProps) => (
  <Mutate<ComposeMessageResponse, unknown, void, ComposeMessageRequest, void>
    verb='POST'
    path={`/api/SecureMessaging/ComposeMessage`}
    {...props}
  />
);

export type UseComposeMessageProps = Omit<
  UseMutateProps<ComposeMessageResponse, unknown, void, ComposeMessageRequest, void>,
  'path' | 'verb'
>;

export const useComposeMessage = (props: UseComposeMessageProps) =>
  useMutate<ComposeMessageResponse, unknown, void, ComposeMessageRequest, void>(
    'POST',
    `/api/SecureMessaging/ComposeMessage`,
    props
  );

export type EnrollmentProps = Omit<
  MutateProps<EnrollmentResponse, unknown, void, EnrollmentRequest, void>,
  'path' | 'verb'
>;

export const Enrollment = (props: EnrollmentProps) => (
  <Mutate<EnrollmentResponse, unknown, void, EnrollmentRequest, void>
    verb='POST'
    path={`/api/Service/Enrollment`}
    {...props}
  />
);

export type UseEnrollmentProps = Omit<
  UseMutateProps<EnrollmentResponse, unknown, void, EnrollmentRequest, void>,
  'path' | 'verb'
>;

export const useEnrollment = (props: UseEnrollmentProps) =>
  useMutate<EnrollmentResponse, unknown, void, EnrollmentRequest, void>('POST', `/api/Service/Enrollment`, props);

export type GeneratePDFProps = Omit<MutateProps<void, unknown, void, EnrollmentRequest, void>, 'path' | 'verb'>;

export const GeneratePDF = (props: GeneratePDFProps) => (
  <Mutate<void, unknown, void, EnrollmentRequest, void> verb='POST' path={`/api/Service/GeneratePdf`} {...props} />
);

export type UseGeneratePDFProps = Omit<UseMutateProps<void, unknown, void, EnrollmentRequest, void>, 'path' | 'verb'>;

export const useGeneratePDF = (props: UseGeneratePDFProps) =>
  useMutate<void, unknown, void, EnrollmentRequest, void>('POST', `/api/Service/GeneratePdf`, props);

export interface IsUniqueEmailQueryParams {
  emailAddress?: string | null;
  applicationName?: string | null;
}

export type IsUniqueEmailProps = Omit<GetProps<UniqueEmailResponse, unknown, IsUniqueEmailQueryParams, void>, 'path'>;

export const IsUniqueEmail = (props: IsUniqueEmailProps) => (
  <Get<UniqueEmailResponse, unknown, IsUniqueEmailQueryParams, void> path={`/api/User/IsUniqueEmail`} {...props} />
);

export type UseIsUniqueEmailProps = Omit<
  UseGetProps<UniqueEmailResponse, unknown, IsUniqueEmailQueryParams, void>,
  'path'
>;

export const useIsUniqueEmail = (props: UseIsUniqueEmailProps) =>
  useGet<UniqueEmailResponse, unknown, IsUniqueEmailQueryParams, void>(`/api/User/IsUniqueEmail`, props);

export interface IsUniqueUserNameQueryParams {
  userName?: string | null;
  applicationName?: string | null;
}

export type IsUniqueUserNameProps = Omit<
  GetProps<UniqueUserNameResponse, unknown, IsUniqueUserNameQueryParams, void>,
  'path'
>;

export const IsUniqueUserName = (props: IsUniqueUserNameProps) => (
  <Get<UniqueUserNameResponse, unknown, IsUniqueUserNameQueryParams, void>
    path={`/api/User/IsUniqueUserName`}
    {...props}
  />
);

export type UseIsUniqueUserNameProps = Omit<
  UseGetProps<UniqueUserNameResponse, unknown, IsUniqueUserNameQueryParams, void>,
  'path'
>;

export const useIsUniqueUserName = (props: UseIsUniqueUserNameProps) =>
  useGet<UniqueUserNameResponse, unknown, IsUniqueUserNameQueryParams, void>(`/api/User/IsUniqueUserName`, props);

export type GetUsersProps = Omit<GetProps<GetUsersResponse, unknown, void, void>, 'path'>;

export const GetUsers = (props: GetUsersProps) => (
  <Get<GetUsersResponse, unknown, void, void> path={`/api/User/GetUsers`} {...props} />
);

export type UseGetUsersProps = Omit<UseGetProps<GetUsersResponse, unknown, void, void>, 'path'>;

export const useGetUsers = (props: UseGetUsersProps) =>
  useGet<GetUsersResponse, unknown, void, void>(`/api/User/GetUsers`, props);

export type CreatePortalUserProps = Omit<
  MutateProps<CreateUserResponse, unknown, void, CreateUserRequest, void>,
  'path' | 'verb'
>;

export const CreatePortalUser = (props: CreatePortalUserProps) => (
  <Mutate<CreateUserResponse, unknown, void, CreateUserRequest, void>
    verb='POST'
    path={`/api/User/CreatePortalUser`}
    {...props}
  />
);

export type UseCreatePortalUserProps = Omit<
  UseMutateProps<CreateUserResponse, unknown, void, CreateUserRequest, void>,
  'path' | 'verb'
>;

export const useCreatePortalUser = (props: UseCreatePortalUserProps) =>
  useMutate<CreateUserResponse, unknown, void, CreateUserRequest, void>('POST', `/api/User/CreatePortalUser`, props);

export type ChangePasswordProps = Omit<
  MutateProps<ChangePasswordResponse, unknown, void, ChangePasswordRequest, void>,
  'path' | 'verb'
>;

export const ChangePassword = (props: ChangePasswordProps) => (
  <Mutate<ChangePasswordResponse, unknown, void, ChangePasswordRequest, void>
    verb='POST'
    path={`/api/User/ChangePassword`}
    {...props}
  />
);

export type UseChangePasswordProps = Omit<
  UseMutateProps<ChangePasswordResponse, unknown, void, ChangePasswordRequest, void>,
  'path' | 'verb'
>;

export const useChangePassword = (props: UseChangePasswordProps) =>
  useMutate<ChangePasswordResponse, unknown, void, ChangePasswordRequest, void>(
    'POST',
    `/api/User/ChangePassword`,
    props
  );

export interface ResetPasswordQueryParams {
  userName?: string | null;
  applicationName?: string | null;
}

export type ResetPasswordProps = Omit<GetProps<ResetPasswordResponse, unknown, ResetPasswordQueryParams, void>, 'path'>;

export const ResetPassword = (props: ResetPasswordProps) => (
  <Get<ResetPasswordResponse, unknown, ResetPasswordQueryParams, void> path={`/api/User/ResetPassword`} {...props} />
);

export type UseResetPasswordProps = Omit<
  UseGetProps<ResetPasswordResponse, unknown, ResetPasswordQueryParams, void>,
  'path'
>;

export const useResetPassword = (props: UseResetPasswordProps) =>
  useGet<ResetPasswordResponse, unknown, ResetPasswordQueryParams, void>(`/api/User/ResetPassword`, props);

export interface ForgotPasswordQueryParams {
  userName?: string | null;
  applicationName?: string | null;
}

export type ForgotPasswordProps = Omit<
  GetProps<ResetPasswordResponse, unknown, ForgotPasswordQueryParams, void>,
  'path'
>;

export const ForgotPassword = (props: ForgotPasswordProps) => (
  <Get<ResetPasswordResponse, unknown, ForgotPasswordQueryParams, void> path={`/api/User/ForgotPassword`} {...props} />
);

export type UseForgotPasswordProps = Omit<
  UseGetProps<ResetPasswordResponse, unknown, ForgotPasswordQueryParams, void>,
  'path'
>;

export const useForgotPassword = (props: UseForgotPasswordProps) =>
  useGet<ResetPasswordResponse, unknown, ForgotPasswordQueryParams, void>(`/api/User/ForgotPassword`, props);

export type VerifyResetTokenProps = Omit<
  MutateProps<VerifyResetTokenResponse, unknown, void, VerifyResetTokenRequest, void>,
  'path' | 'verb'
>;

export const VerifyResetToken = (props: VerifyResetTokenProps) => (
  <Mutate<VerifyResetTokenResponse, unknown, void, VerifyResetTokenRequest, void>
    verb='POST'
    path={`/api/User/VerifyResetToken`}
    {...props}
  />
);

export type UseVerifyResetTokenProps = Omit<
  UseMutateProps<VerifyResetTokenResponse, unknown, void, VerifyResetTokenRequest, void>,
  'path' | 'verb'
>;

export const useVerifyResetToken = (props: UseVerifyResetTokenProps) =>
  useMutate<VerifyResetTokenResponse, unknown, void, VerifyResetTokenRequest, void>(
    'POST',
    `/api/User/VerifyResetToken`,
    props
  );

export type UpdatePasswordProps = Omit<
  MutateProps<UpdatePasswordResponse, unknown, void, UpdatePasswordRequest, void>,
  'path' | 'verb'
>;

export const UpdatePassword = (props: UpdatePasswordProps) => (
  <Mutate<UpdatePasswordResponse, unknown, void, UpdatePasswordRequest, void>
    verb='POST'
    path={`/api/User/UpdatePassword`}
    {...props}
  />
);

export type UseUpdatePasswordProps = Omit<
  UseMutateProps<UpdatePasswordResponse, unknown, void, UpdatePasswordRequest, void>,
  'path' | 'verb'
>;

export const useUpdatePassword = (props: UseUpdatePasswordProps) =>
  useMutate<UpdatePasswordResponse, unknown, void, UpdatePasswordRequest, void>(
    'POST',
    `/api/User/UpdatePassword`,
    props
  );

export type CreateUserProps = Omit<
  MutateProps<CreateUserResponse, unknown, void, CreateUserRequest, void>,
  'path' | 'verb'
>;

export const CreateUser = (props: CreateUserProps) => (
  <Mutate<CreateUserResponse, unknown, void, CreateUserRequest, void>
    verb='POST'
    path={`/api/User/CreateUser`}
    {...props}
  />
);

export type UseCreateUserProps = Omit<
  UseMutateProps<CreateUserResponse, unknown, void, CreateUserRequest, void>,
  'path' | 'verb'
>;

export const useCreateUser = (props: UseCreateUserProps) =>
  useMutate<CreateUserResponse, unknown, void, CreateUserRequest, void>('POST', `/api/User/CreateUser`, props);

export type UpdateUserProps = Omit<
  MutateProps<UpdateUserResponse, unknown, void, UpdateUserRequest, void>,
  'path' | 'verb'
>;

export const UpdateUser = (props: UpdateUserProps) => (
  <Mutate<UpdateUserResponse, unknown, void, UpdateUserRequest, void>
    verb='POST'
    path={`/api/User/UpdateUser`}
    {...props}
  />
);

export type UseUpdateUserProps = Omit<
  UseMutateProps<UpdateUserResponse, unknown, void, UpdateUserRequest, void>,
  'path' | 'verb'
>;

export const useUpdateUser = (props: UseUpdateUserProps) =>
  useMutate<UpdateUserResponse, unknown, void, UpdateUserRequest, void>('POST', `/api/User/UpdateUser`, props);

export type SetRoleProps = Omit<
  MutateProps<UpdateAdminResponse, unknown, void, UpdateAdminRequest, void>,
  'path' | 'verb'
>;

export const SetRole = (props: SetRoleProps) => (
  <Mutate<UpdateAdminResponse, unknown, void, UpdateAdminRequest, void>
    verb='POST'
    path={`/api/User/SetRole`}
    {...props}
  />
);

export type UseSetRoleProps = Omit<
  UseMutateProps<UpdateAdminResponse, unknown, void, UpdateAdminRequest, void>,
  'path' | 'verb'
>;

export const useSetRole = (props: UseSetRoleProps) =>
  useMutate<UpdateAdminResponse, unknown, void, UpdateAdminRequest, void>('POST', `/api/User/SetRole`, props);

export type SetStateProps = Omit<
  MutateProps<SetUserStateResponse, unknown, void, SetUserStateRequest, void>,
  'path' | 'verb'
>;

export const SetState = (props: SetStateProps) => (
  <Mutate<SetUserStateResponse, unknown, void, SetUserStateRequest, void>
    verb='POST'
    path={`/api/User/SetState`}
    {...props}
  />
);

export type UseSetStateProps = Omit<
  UseMutateProps<SetUserStateResponse, unknown, void, SetUserStateRequest, void>,
  'path' | 'verb'
>;

export const useSetState = (props: UseSetStateProps) =>
  useMutate<SetUserStateResponse, unknown, void, SetUserStateRequest, void>('POST', `/api/User/SetState`, props);

export type GetUserNotificationPreferencesProps = Omit<
  GetProps<NotificationPreferencesResponse, unknown, void, void>,
  'path'
>;

export const GetUserNotificationPreferences = (props: GetUserNotificationPreferencesProps) => (
  <Get<NotificationPreferencesResponse, unknown, void, void> path={`/api/User/NotificationPreferences`} {...props} />
);

export type UseGetUserNotificationPreferencesProps = Omit<
  UseGetProps<NotificationPreferencesResponse, unknown, void, void>,
  'path'
>;

export const useGetUserNotificationPreferences = (props: UseGetUserNotificationPreferencesProps) =>
  useGet<NotificationPreferencesResponse, unknown, void, void>(`/api/User/NotificationPreferences`, props);

export type SaveUserNotificationPreferencesProps = Omit<
  MutateProps<PostUserNotificationPreferencesResponse, unknown, void, UserNotificationPreference, void>,
  'path' | 'verb'
>;

export const SaveUserNotificationPreferences = (props: SaveUserNotificationPreferencesProps) => (
  <Mutate<PostUserNotificationPreferencesResponse, unknown, void, UserNotificationPreference, void>
    verb='POST'
    path={`/api/User/NotificationPreferences`}
    {...props}
  />
);

export type UseSaveUserNotificationPreferencesProps = Omit<
  UseMutateProps<PostUserNotificationPreferencesResponse, unknown, void, UserNotificationPreference, void>,
  'path' | 'verb'
>;

export const useSaveUserNotificationPreferences = (props: UseSaveUserNotificationPreferencesProps) =>
  useMutate<PostUserNotificationPreferencesResponse, unknown, void, UserNotificationPreference, void>(
    'POST',
    `/api/User/NotificationPreferences`,
    props
  );

export interface GetEnrollmentModuleSummaryUrlPathParams {
  moduleEnrollmentId: string;
  externalPracticeId: string;
}

export type GetEnrollmentModuleSummaryUrlProps = Omit<
  GetProps<EnrollmentModuleSummaryResponse, unknown, void, GetEnrollmentModuleSummaryUrlPathParams>,
  'path'
> &
  GetEnrollmentModuleSummaryUrlPathParams;

/**
 * Your Enrollment summary response
 */
export const GetEnrollmentModuleSummaryUrl = ({
  moduleEnrollmentId,
  externalPracticeId,
  ...props
}: GetEnrollmentModuleSummaryUrlProps) => (
  <Get<EnrollmentModuleSummaryResponse, unknown, void, GetEnrollmentModuleSummaryUrlPathParams>
    path={`enrollment/summary/${moduleEnrollmentId}/${externalPracticeId}`}
    {...props}
  />
);

export type UseGetEnrollmentModuleSummaryUrlProps = Omit<
  UseGetProps<EnrollmentModuleSummaryResponse, unknown, void, GetEnrollmentModuleSummaryUrlPathParams>,
  'path'
> &
  GetEnrollmentModuleSummaryUrlPathParams;

/**
 * Your Enrollment summary response
 */
export const useGetEnrollmentModuleSummaryUrl = ({
  moduleEnrollmentId,
  externalPracticeId,
  ...props
}: UseGetEnrollmentModuleSummaryUrlProps) =>
  useGet<EnrollmentModuleSummaryResponse, unknown, void, GetEnrollmentModuleSummaryUrlPathParams>(
    (paramsInPath: GetEnrollmentModuleSummaryUrlPathParams) =>
      `enrollment/summary/${paramsInPath.moduleEnrollmentId}/${paramsInPath.externalPracticeId}`,
    { pathParams: { moduleEnrollmentId, externalPracticeId }, ...props }
  );

export type UseCreateRxAdminDoseProps = Omit<
  UseMutateProps<CreateRxAdminDoseResponse, unknown, void, RxAdminDose, void>,
  'path' | 'verb'
>;
export type CreateAddRxAminDoseProps = Omit<
  MutateProps<CreateRxAdminDoseResponse, unknown, void, RxAdminDose, void>,
  'path' | 'verb'
>;
export const CreateRxAdminDose = (props: CreateAddRxAminDoseProps) => (
  <Mutate<CreateRxAdminDoseResponse, unknown, void, RxAdminDose, void>
    verb='POST'
    path={`/api/RxAdmin/AddDose`}
    {...props}
  />
);

export const useCreateRxAdminDose = (props: UseCreateRxAdminDoseProps) =>
  useMutate<CreateRxAdminDoseResponse, unknown, void, RxAdminDose, void>('POST', `/api/RxAdmin/AddDose`, props);

export type UseEditRxAdminDoseProps = Omit<
  UseMutateProps<EditRxAdminDoseResponse, unknown, void, EditRxAdminDose, void>,
  'path' | 'verb'
>;
export type EditRxAminDoseProps = Omit<
  MutateProps<EditRxAdminDoseResponse, unknown, void, EditRxAdminDose, void>,
  'path' | 'verb'
>;
export const EditRxAdminDose = (props: EditRxAminDoseProps) => (
  <Mutate<EditRxAdminDoseResponse, unknown, void, EditRxAdminDose, void>
    verb='PUT'
    path={`/api/RxAdmin/EditDose`}
    {...props}
  />
);

export const useEditRxAdminDose = (props: UseEditRxAdminDoseProps) =>
  useMutate<EditRxAdminDoseResponse, unknown, void, EditRxAdminDose, void>('PUT', `/api/RxAdmin/EditDose`, props);

export type GetRxAdminCheckSrExistProps = Omit<
  GetProps<RxAdminCheckSRExistResponse, unknown, RxAdminCheckSRExistsQueryParams, void>,
  'path'
>;

export const CheckRxAdminSRExist = (props: GetRxAdminCheckSrExistProps) => (
  <Get<RxAdminCheckSRExistResponse, unknown, RxAdminCheckSRExistsQueryParams, void>
    path={`/api/RxAdmin/CheckRxAdminSrExist`}
    {...props}
  />
);
export type UseRxAdminSRExistProps = Omit<
  UseGetProps<RxAdminCheckSRExistResponse, unknown, RxAdminCheckSRExistsQueryParams, void>,
  'path'
>;

export const useCheckRxAdminSRExist = (props: UseRxAdminSRExistProps) =>
  useGet<RxAdminCheckSRExistResponse, unknown, RxAdminCheckSRExistsQueryParams, void>(
    `/api/RxAdmin/CheckRxAdminSrExist`,
    props
  );

export type GetRxAdminCheckStatusExistProps = Omit<
  GetProps<RxAdminCheckStatusExistResponse, unknown, RxAdminCheckStatusExistQueryParams, void>,
  'path'
>;

export const CheckRxAdminStatusExist = (props: GetRxAdminCheckSrExistProps) => (
  <Get<RxAdminCheckStatusExistResponse, unknown, RxAdminCheckStatusExistQueryParams, void>
    path={`/api/RxAdmin/CheckRxAdminStatus`}
    {...props}
  />
);
export type UseRxAdminStatusExistProps = Omit<
  UseGetProps<RxAdminCheckStatusExistResponse, unknown, RxAdminCheckStatusExistQueryParams, void>,
  'path'
>;

export const useCheckRxAdminStatusExist = (props: UseRxAdminSRExistProps) =>
  useGet<RxAdminCheckStatusExistResponse, unknown, RxAdminCheckStatusExistQueryParams, void>(
    `/api/RxAdmin/CheckRxAdminStatus`,
    props
  );
