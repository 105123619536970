import { OktaAuth } from '@okta/okta-auth-js';
import { SecureRoute, Security } from '@okta/okta-react';
import { History } from 'history';
import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route, RouteComponentProps, Router, Switch, useHistory } from 'react-router-dom';
import { HcpLoginCallback, PatientProfileDetails } from './components';
import { FeatureGuard } from './components/multi-tenant/FeatureGuard';
import { AnyType, OktaConfig } from './interfaces';
import { AppRootState } from './redux';
import { ROUTE_PATHS } from './utils';
import * as views from './views';
import { PatientProfileView } from './views';

const PUBLIC_ROUTES: AppRoute[] = [
  { path: ROUTE_PATHS.loginCallback, component: HcpLoginCallback },
  { path: ROUTE_PATHS.login, component: views.LoginView },
  { path: ROUTE_PATHS.baa, component: views.BAAView },
  { path: ROUTE_PATHS.forgotPassword, component: views.ForgotPasswordView },
  { path: ROUTE_PATHS.forgotPasswordSuccess, component: views.ForgotPasswordSuccessView },
  { path: ROUTE_PATHS.resetPasswordToken, component: views.ResetPasswordView },
  { path: ROUTE_PATHS.privacy, component: views.PrivacyPolicyView },
  { path: ROUTE_PATHS.termsAndConditions, component: views.TermsAndConditionsView },
  { path: ROUTE_PATHS.terms, component: views.TermsOfUseView },
  { path: ROUTE_PATHS.registration, component: views.RegistrationView },
  { path: ROUTE_PATHS.registrationWelcome, component: views.RegistrationWelcomeView },
  { path: ROUTE_PATHS.registrationSubmitted, component: views.RegistrationSubmitView }
];

const PRIVATE_ROUTES: AppRoute[] = [
  { path: ROUTE_PATHS.home, component: views.HomeView },
  { path: ROUTE_PATHS.messages, component: views.MessagesView, featureKey: 'messages.manage' },
  { path: ROUTE_PATHS.message, component: views.MessageView },
  { path: ROUTE_PATHS.patientsNew, component: views.AddPatientView, featureKey: 'patient-management.manage' },
  { path: ROUTE_PATHS.patients, component: views.PatientsView },
  { path: ROUTE_PATHS.patient, component: views.PatientProfileView },
  { path: `${ROUTE_PATHS.patient}/:caseId`, component: PatientProfileView },
  { path: ROUTE_PATHS.prescriber, component: views.PrescriberView },
  { path: ROUTE_PATHS.requests, component: views.RequestsView },
  { path: ROUTE_PATHS.cases, component: views.CasesView, featureKey: 'case-journey.manage' },
  { path: ROUTE_PATHS.resources, component: views.ResourcesView },
  {
    path: ROUTE_PATHS.caseRequestsSubmitted,
    component: views.RequestSubmitView
  },
  {
    path: ROUTE_PATHS.requestsSubmitted,
    component: views.RequestSubmitView,
    featureKey: 'service-request.manage'
  },
  {
    path: ROUTE_PATHS.requestServiceIntermediator,
    component: views.RequestServiceIntermediator
  },
  {
    path: ROUTE_PATHS.caseServiceRequest,
    component: views.ServiceRequestView
  },
  { path: ROUTE_PATHS.serviceRequest, component: views.ServiceRequestView, featureKey: 'service-request.manage' },
  {
    path: ROUTE_PATHS.serviceRequestForPatient,
    component: views.ServiceRequestView,
    featureKey: 'service-request.manage'
  },
  {
    path: ROUTE_PATHS.serviceRequestSelected,
    component: views.SelectedServiceRequestView,
    featureKey: 'service-request.manage'
  },
  { path: ROUTE_PATHS.requestEnrollment, component: views.RequestDetailsView, featureKey: 'service-request.manage' },
  { path: ROUTE_PATHS.request, component: views.RequestDetailsView, featureKey: 'service-request.manage' },
  { path: ROUTE_PATHS.rxModule, component: views.RxModule },
  { path: ROUTE_PATHS.settings, component: views.SettingsView },
  { path: ROUTE_PATHS.caseDetail, component: views.CaseDetailView, featureKey: 'case-journey.manage' },
  {
    path: ROUTE_PATHS.addEnrollmentService,
    component: views.AddEnrollmentServiceRecord,
    featureKey: 'isEnrollmentUsingModule'
  },
  { path: ROUTE_PATHS.enrollmentModule, component: views.EnrollmentModuleView },
  { path: ROUTE_PATHS.landingPage, component: views.LandingPage }
];

const ADMIN_ONLY_ROUTES: AppRoute[] = [
  { path: ROUTE_PATHS.practice, component: views.PracticeManagementView, featureKey: 'practice-management.manage' },
  {
    path: ROUTE_PATHS.prescriberNew,
    component: views.AddPrescriberView,
    featureKey: 'practice-management.prescribers.manage'
  },
  {
    path: ROUTE_PATHS.locationsNew,
    component: views.AddLocationView,
    featureKey: 'practice-management.locations.manage'
  },
  { path: ROUTE_PATHS.userNew, component: views.AddUserView, featureKey: 'practice-management.users.manage' }
];

const buildPublicRoutes = mapToRoutes(PUBLIC_ROUTES, Route);
const buildPrivateRoutes = mapToRoutes(PRIVATE_ROUTES, SecureRoute);
const buildAdminOnlyRoutes = mapToAdminRoutes(ADMIN_ONLY_ROUTES, SecureRoute);

interface AppRoutingProps {
  history: History;
  config: OktaConfig;
  oktaAuth: OktaAuth;
}

interface AppRoute {
  component: AnyType;
  path: string;
  featureKey?: string;
}

export const AppRouting = (props: AppRoutingProps) => {
  const history = useHistory();

  const onAuthRequired = useCallback(async () => {
    history.push(ROUTE_PATHS.login);
  }, [history]);

  return (
    <Router history={props.history}>
      <Security {...props.config} oktaAuth={props.oktaAuth} onAuthRequired={onAuthRequired}>
        <Switch>
          {buildPublicRoutes}
          {buildAdminOnlyRoutes}
          {buildPrivateRoutes}
          <Route path='*'>
            <Redirect to={ROUTE_PATHS.home} />
          </Route>
        </Switch>
      </Security>
    </Router>
  );
};

const renderHomeRedirect = () => <Redirect to={ROUTE_PATHS.home} />;

interface CustomRouteHandlerProps {
  component: typeof Route;
  featureKey?: string;
  routeProps: RouteComponentProps;
}

const FeatureRouteHandler = ({ component: Component, ...props }: CustomRouteHandlerProps) => {
  return (
    <FeatureGuard contentKey={props.featureKey} disabledHandler={renderHomeRedirect}>
      <Component {...props.routeProps} />
    </FeatureGuard>
  );
};

function mapToRoutes(routes: AppRoute[], RouteComponent: typeof Route | typeof SecureRoute) {
  return routes.map((route) => {
    return (
      <RouteComponent
        exact
        key={route.path}
        path={route.path}
        render={(routeProps) => (
          <FeatureRouteHandler component={route.component} featureKey={route.featureKey} routeProps={routeProps} />
        )}
      />
    );
  });
}

const AdminRouteHandler = ({ component: Component, ...props }: CustomRouteHandlerProps) => {
  const isAuthenticated = useSelector((state: AppRootState) => !!state.auth.session?.user);
  const isAdmin = useSelector((state: AppRootState) => state.auth?.isAdmin);

  // See if the value even exists. If it doesn't, authentication hasn't happened yet so wait until it auth is done.
  if (isAdmin === undefined) return null;

  if (!isAuthenticated || (isAuthenticated && !isAdmin)) return renderHomeRedirect();

  return <Component {...props.routeProps} />;
};

function mapToAdminRoutes(routes: AppRoute[], RouteComponent: typeof Route | typeof SecureRoute) {
  return routes.map((route) => {
    return (
      <RouteComponent
        exact
        key={route.path}
        path={route.path}
        render={(routeProps) => (
          <FeatureGuard contentKey={route.featureKey} disabledHandler={renderHomeRedirect}>
            <AdminRouteHandler component={route.component} routeProps={routeProps} />
          </FeatureGuard>
        )}
      />
    );
  });
}
