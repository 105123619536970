import { SERVICES_GATEWAY_URL, STANDARD_PORTAL_APPLICATION_NAME } from '../api';
import { ConsentModeOptionType, ConsentType, EmailSmsConsentMode } from '../components/consent-modal/types';
import { ServiceOptionType } from '../interfaces';

export const standardPortalConfig = {
  url: 'https://standardhcpportal-dev.caremetx.com',
  isStandardPortalUrl:
    window.location.origin === 'https://standardhcpportal-dev.caremetx.com' ||
    window.location.origin === 'https://standardhcpportal-demo.caremetx.com' ||
    window.location.origin === 'https://standardhcpportal-pte.caremetx.com' ||
    window.location.origin === 'https://standardhcpportal-dte.caremetx.com' ||
    window.location.origin === 'https://standardhcpportal-qa.caremetx.com' ||
    window.location.origin === 'https://standardhcpportal.caremetx.com' ||
    window.location.origin === 'https://hcp-portal-111.cmtx-aws.com',
  applicationName: STANDARD_PORTAL_APPLICATION_NAME
  // authUsername: STANDARD_PORTAL_USERNAME,
  // authPassword: STANDARD_PORTAL_PASSWORD
};

export const IS_HTML = true;
export const REQUIRED = true;
export const NOT_REQUIRED = false;
export const CALLBACK_PATH = '/implicit/callback';
export const FORM_WIZARD_VALUES = {
  pages: {
    registration: {
      names: {
        organization: 'organization',
        prescriber: 'prescriber',
        termsAndConditions: 'terms',
        baa: 'baa'
      }
    }
  }
};
export const ACCESS_URL = 'https://www.access.leqviohcp.com';
export const RESOURCES_URL = `${ACCESS_URL}/resources`;
export const RX_MODULES: ServiceOptionType[] = [
  ServiceOptionType.PharmacyElectronicBenefitVerification,
  ServiceOptionType.MedicalElectronicBenefitVerification,
  ServiceOptionType.PharmacyElectronicPriorAuthorization,
  ServiceOptionType.MedicalElectronicPriorAuthorization
];
export const ROUTE_PATHS = {
  loginCallback: CALLBACK_PATH,
  landingPage: '/',
  baa: '/baa',
  cases: '/cases',
  caseDetail: '/patients/:patientId/cases/:caseId',
  addEnrollmentService: '/enrollmentSR',
  patientCaseDetail: '/patients/:patientId/caseId',
  forgotPassword: '/forgot-password',
  forgotPasswordSuccess: '/forgot-password/success',
  resetPasswordToken: '/reset-password/:token',
  login: '/login',
  privacy: '/privacy',
  termsAndConditions: '/terms-and-conditions',
  terms: '/terms',
  registration: '/registration',
  registrationWelcome: '/registration/welcome',
  registrationSubmitted: '/registration/submitted',
  home: '/home',
  locationsNew: '/locations/new',
  messages: '/messages',
  message: '/messages/:id',
  organization: '/organization',
  patients: '/patients',
  patient: '/patients/:id',
  patientsNew: '/patients/new',
  practice: '/practice',
  prescribers: '/prescribers',
  prescriber: '/prescribers/:id',
  prescriberNew: '/prescribers/new',
  requests: '/requests',
  request: '/requests/:id/:patientId/:requestType/:requestId',
  requestEnrollment: '/requests/:id/:patientId/:requestType/:requestId/:enrollmentGuid',
  caseRequestsSubmitted: '/case/requests/submitted',
  requestsSubmitted: '/requests/submitted',
  requestServiceIntermediator: '/requests/intermediator',
  resources: '/resources',
  caseServiceRequest: '/requests/start/case/:caseId/patient/:patientId/:caseDrugName',
  serviceRequest: '/requests/start',
  serviceRequestEnrollment: '/requests/start/enrollment',
  serviceRequestForPatient: '/requests/start/patient/:patientId',
  serviceRequestSelected: '/requests/new/:service/patient/:patientId',
  settings: '/settings',
  userNew: '/users/new',
  rxModule: '/patient/:id/:service',
  enrollmentModule: '/enrollmentModule',
  contactUs: '/contactUs',
  enrollmentSummary: 'enrollment/summary/'
};
export const MIN_BIRTHDATE = '1900-01-01';
export const FINISHED_EVENT = 'FINISHED';

export const REGENERON_PROVIDER_PORTAL_NAME = 'RegeneronProviderPortal';

// tooltip constants
export const ACTION_NEEDED = 'Action needed';
export const RECENTLY_UPDATED = 'Recently updated';
export const UNREAD_MESSAGE = 'Unread Message';
export const OUTGOING_MESSAGE = 'Outgoing message';
export const INCOMING_MESSAGE = 'Incoming message';
export const REPLY = 'Reply';
export const ATTACHMENT = 'Attachment';
export const SENT_MESSAGE = 'Sent Message';
export const REFRESH = 'Refresh';
export const INCOMPLETE_ACTIVE_JOURNEY_STEP = 'The Journey Step is active and incomplete';
export const ACTIVE_JOURNEY_STEP = 'The Journey step is active and is completed';

// Document source constants
export const OUTBOUND_EMAIL = 'Outbound Email';

//enrollment events
export enum EnrollmentEvents {
  OnSubmitSuccess = 'Submit-Successful',
  OnFinish = 'Finish',
  OnCancel = 'Cancel'
}

// Put validation message here so that anyone can utilize it across the application
export const HIN_VALIDATION_MESSAGE = 'HIN needs to be in alphaNumeric';
export const EMAIL_VALIDATION_MESSAGE = 'Email is invalid';
export const REQUIRED_MESSAGE = 'Required';

// Put validation REGEX here so that anyone can utilize it across the application
export const HIN_REGEX = /^(?=.*\d)(?=.*[A-Za-z])[A-Za-z0-9]{1,9}$/;
export const EMAIL_REGEX = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{1,}))$/;
export const NUMBER_REGEX = new RegExp(/^\d*\.?\d*$/);
export const MIN_UPDATED_DATE = '01-01-0001';

// Consent Modal constants
export const SEND_INVITE_API = `${SERVICES_GATEWAY_URL}/ehipaa/v1/api/invites`;
export const CHECK_STATUS_API = `${SERVICES_GATEWAY_URL}/ehipaa/v1/api/pafs/checkConsentStatusbyDrug`;
export const CONSENT_MODAL_LABEL = {
  UPDATE_CONSENT: 'Update Consent',
  REQUEST_CONSENT: 'Request Consent',
  REQUEST_CONSENT_HEADER: 'Request Patient Consent',
  UPDATE_CONSENT_HEADER: 'Update Patient Consent',
  SELECT_CONSENT_TYPE: 'Select patient consent request type:',
  CHECKING_STATUS: 'Checking status',
  CHECK_STATUS: 'Check status',
  SEND_INVITATION: 'Send invitation',
  SENDING_INVITATION: 'Sending invitation',
  RESEND_INVITATION: 'Re-send invitation',
  GET_VERIFICATION_CODE: 'Get verification code',
  GENERATING_CODE: 'Generating code',
  NEW_VERIFICATION_CODE: 'New verification code',
  CLOSE: 'Close',
  EMAIL: 'Email',
  SMS: 'SMS (Text)',
  UPLOAD: 'Upload',
  ONSITE: 'Verification Code (On-Site)',
  PATIENT_EMAIL: 'Patient Email Address',
  CAREGIVER_EMAIL: 'Caregiver Email Address',
  PATIENT_MOBILE: 'Patient Mobile Number',
  CAREGIVER_MOBILE: 'Caregiver Mobile Number',
  UPLOAD_DECLARATION: `By uploading this form I certify that it has been signed by the patient or the patient’s personal
  representative in accordance with applicable law.`,
  UPLOAD_FILE: 'Upload a file',
  YES: 'Yes',
  NO: 'No'
};

export const REQUEST_CONSENT_OPTIONS = [
  { label: CONSENT_MODAL_LABEL.EMAIL, value: ConsentType.EMAIL },
  { label: CONSENT_MODAL_LABEL.SMS, value: ConsentType.SMS },
  { label: CONSENT_MODAL_LABEL.UPLOAD, value: ConsentType.UPLOAD },
  { label: CONSENT_MODAL_LABEL.ONSITE, value: ConsentType.ONSITE }
];
export const EMAIL_CONSENT_MODES: ConsentModeOptionType[] = [
  { contactPerson: 'patient', label: CONSENT_MODAL_LABEL.PATIENT_EMAIL, value: EmailSmsConsentMode.PATIENT_EMAIL },
  { contactPerson: 'caregiver', label: CONSENT_MODAL_LABEL.CAREGIVER_EMAIL, value: EmailSmsConsentMode.CAREGIVER_EMAIL }
];

export const SMS_CONSENT_MODES: ConsentModeOptionType[] = [
  { contactPerson: 'patient', label: CONSENT_MODAL_LABEL.PATIENT_MOBILE, value: EmailSmsConsentMode.PATIENT_MOBILE },
  {
    contactPerson: 'caregiver',
    label: CONSENT_MODAL_LABEL.CAREGIVER_MOBILE,
    value: EmailSmsConsentMode.CAREGIVER_MOBILE
  }
];

export const EMAIL_SMS_CONSENT_MODES: Record<string, ConsentModeOptionType[]> = {
  Email: EMAIL_CONSENT_MODES,
  Sms: SMS_CONSENT_MODES
};

export const HCP_EHIPAA_GENDER_MAP: Record<string, string> = {
  M: 'Male',
  F: 'Female'
};

export const CONSENT_MODAL_ERROR_MSG: Record<string, string> = {
  SEND_INVITE_ERROR: 'An error occured in sending invite. Please try again.',
  CHECK_STATUS_ERROR: 'An error occured in checking status. Please try again',
  GENERIC_ERROR: 'Something went wrong. Please try again.'
};

export const CONSENT_STATUS_ON_FILE_ENABLED = false;
