import componentTypes from '@data-driven-forms/react-form-renderer/dist/cjs/component-types';
import useFieldApi, { UseFieldApiConfig } from '@data-driven-forms/react-form-renderer/dist/cjs/use-field-api';
import useFormApi from '@data-driven-forms/react-form-renderer/dist/cjs/use-form-api';
import suirComponentMapper from '@data-driven-forms/suir-component-mapper/dist/cjs/component-mapper';
import { AnyObject } from 'final-form';
import React, { useCallback, useEffect, useState } from 'react';
import { Button, Col, Form, InputGroup } from 'react-bootstrap';
import { X } from 'react-bootstrap-icons';
import { Typeahead, TypeaheadProps } from 'react-bootstrap-typeahead';
import NumberFormat from 'react-number-format';
import { DashCircleFill } from 'react-bootstrap-icons';

import { asyncComponentMapper, DisplayErrorOrHelperText, DisplayErrorOrHelperTextFeedback, validationProps } from '.';
import { ContentForRequiredScroll, FormFormatField, HcpUpload } from '..';
import { AnyType, FilePreview } from '../../interfaces';
import { FIELD_NAMES, rawCleanHtmlProps, rawHtmlProps } from '../../utils';
import HcpFormWizard from '../FormWizard/ServiceRequestFormWizard';
import { HcpMedicscanUpload } from '../HcpMedicscanUpload';
import ReviewTab from '../ReviewTab';
import InfoIcon from '../InfoIcon';
import { HCPPlainSignatureDateField, HCPPlainSignatureField, HCPSignatureField } from './HcpSignatureFields';
import { searchIcon } from '../../views';
import { from } from '@apollo/client';
import ReactDatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const { prescriberFields, clinicalFields, rxAdminDoseFields } = FIELD_NAMES;

export const HcpSpacer = ({ empty, sizes, ...props }: UseFieldApiConfig) =>
  empty && !props.sizes ? <></> : <Form.Group as={Col} className='m-0' {...sizes} {...props} id={props.name} />;

export const HcpSeparator = (props: UseFieldApiConfig) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { label, helperText, input, meta, ...fieldProps } = useFieldApi(props);
  return (
    <Form.Group as={Col} sm={12} id={props.name}>
      <hr {...fieldProps} />
    </Form.Group>
  );
};

export const HcpSection = ({ name, label, subsection, subsectionuncaps, ...props }: UseFieldApiConfig) => {
  return (
    <Col sm={12} id={name} {...props}>
      <p
        className={`${
          subsection ? 'text-capitalize sub-section' : subsectionuncaps ? 'sub-section' : 'text-uppercase section'
        }-text`}>
        {label}
      </p>
    </Col>
  );
};

export const HcpHeaderWithPasswordRequirement = ({
  name,
  label,
  subsection,
  subsectionuncaps,
  ...props
}: UseFieldApiConfig) => {
  return (
    <Col sm={12} id={name} {...props}>
      <p
        className={`${
          subsection ? 'text-capitalize sub-section' : subsectionuncaps ? 'sub-section' : 'text-uppercase section'
        }-text`}>
        {label}
        <InfoIcon arrow='arrow-left' {...props} />
      </p>
    </Col>
  );
};

export const HcpTitleSection = ({ name, label, underlined, ...props }: UseFieldApiConfig) => (
  <Col sm={1} id={name} {...props}>
    <p className={`font-weight-bold text-muted text-capitalize fs-2 text-nowrap ${underlined ? 'text-underline' : ''}`}>
      {label}
    </p>
  </Col>
);

export const HcpTitleSectionWithWrap = ({ name, label, className, ...props }: UseFieldApiConfig) => (
  <Col id={name} {...props} className={className}>
    <p className={`${props?.customClass}`} style={{ color: '#8f8f8f' }}>
      {label}
    </p>
  </Col>
);

export const HcpPlainTextSection = (props: UseFieldApiConfig) => {
  const fieldProps = useFieldApi(props);

  return (
    <Col {...props.sizes}>
      <div {...fieldProps} {...rawCleanHtmlProps(fieldProps?.content)} className='fs-4 mb-2' />
    </Col>
  );
};

export const HcpPlainTextMultipleSelectSection = (props: UseFieldApiConfig) => {
  const { label, helperText, input, meta, ...fieldProps } = useFieldApi(props);
  let csv = input?.value;
  if (!!props?.options && Array.isArray(props?.options)) {
    csv = props?.options.find((data) => data?.value === input?.value)?.label;
  }
  if (Array.isArray(input?.value) && !props?.options) {
    csv = input?.value?.map((value: { label: string; value: string }) => value?.label).join(', ');
  }

  // styles borrowed from .form-input
  const styles = {
    height: 'calc(1.5em + 0.75rem + 2px)',
    lineHeight: 1.5,
    padding: '0.375rem 0'
  };

  return (
    <Form.Group as={Col} {...props.sizes}>
      {label && <Form.Label className='text-uppercase'>{label}</Form.Label>}
      <div {...fieldProps} className='fs-4' style={styles}>
        {csv}
      </div>
      <DisplayErrorOrHelperTextFeedback meta={meta} helperText={helperText} />
    </Form.Group>
  );
};

export const HCPItalicTextSection = (props: UseFieldApiConfig) => {
  const fieldProps = useFieldApi(props);
  const styles = {
    color: 'gray',
    marginLeft: '10%'
  };
  return (
    <Col {...props.sizes}>
      <div {...fieldProps} {...rawCleanHtmlProps(fieldProps?.content)} className='fs-3 font-italic' style={styles} />
    </Col>
  );
};
export const HcpPlainTextTypeField = (props: UseFieldApiConfig) => {
  const { label, helperText, input, meta, ...fieldProps } = useFieldApi(props);
  // styles borrowed from .form-input
  const styles = {
    height: 'calc(1.5em + 0.75rem + 2px)',
    lineHeight: 1.5,
    padding: '0.375rem 0'
  };
  if (typeof input?.value === 'object') {
    if (
      input?.value?.some((cred: string) => cred === 'mobile') &&
      input?.value?.some((cred: string) => cred === 'home')
    ) {
      input.value = 'home, mobile';
    }
  }
  return (
    <Form.Group as={Col} {...props.sizes}>
      {label && <Form.Label className='text-uppercase'>{label}</Form.Label>}
      <div {...fieldProps} className='fs-4' style={styles}>
        {input.value}
      </div>
      <DisplayErrorOrHelperTextFeedback meta={meta} helperText={helperText} />
    </Form.Group>
  );
};

export const HcpAddressLocationTextField = (props: UseFieldApiConfig) => {
  const { label, helperText, input, meta, ...fieldProps } = useFieldApi(props);
  const form = useFormApi();
  const address1 = form.getState().values[prescriberFields?.locationAddress1];
  const address2 = form.getState().values[prescriberFields?.locationAddress2];
  const city = form.getState().values[prescriberFields?.locationAddressCity];
  const state = form.getState().values[prescriberFields?.locationAddressState];
  const zip = form.getState().values[prescriberFields?.locationAddressZip];

  input.value = `${address1}
    ${address2 ?? ''} |
    ${city}
    ${state || zip ? ', ' : ''}
    ${state ?? ''}
    ${zip ?? ''}`;
  // styles borrowed from .form-input
  const styles = {
    height: 'calc(1.5em + 0.75rem + 2px)',
    lineHeight: 1.5,
    padding: '0.375rem 0'
  };
  return (
    <Form.Group as={Col} {...props.sizes}>
      {label && <Form.Label className='text-uppercase'>{label}</Form.Label>}
      <div {...fieldProps} className='fs-4' style={styles}>
        {input.value}
      </div>
      <DisplayErrorOrHelperTextFeedback meta={meta} helperText={helperText} />
    </Form.Group>
  );
};

export const HcpPlainTextFieldDiagnosisListItems = (props: UseFieldApiConfig) => {
  const { label, helperText, input, meta, ...fieldProps } = useFieldApi(props);
  // styles borrowed from .form-input
  const styles = {
    height: 'auto',
    lineHeight: 1.5,
    padding: '0.375rem 0'
  };
  return (
    <Form.Group as={Col} {...props.sizes}>
      <div {...fieldProps} className='fs-4' style={styles}>
        <ul>
          {input?.value?.map((item: any, index: number) => (
            <li key={index}>{item.icdcode + ' - ' + item.icddescription}</li>
          ))}
        </ul>
      </div>
    </Form.Group>
  );
};

export const HcpPlainTextField = (props: UseFieldApiConfig) => {
  const { label, helperText, input, meta, ...fieldProps } = useFieldApi(props);
  // styles borrowed from .form-input
  const styles = {
    height: 'calc(1.5em + 0.75rem + 2px)',
    lineHeight: 1.5,
    padding: '0.375rem 0'
  };
  return (
    <Form.Group as={Col} {...props.sizes}>
      {label && <Form.Label className='text-uppercase'>{label}</Form.Label>}
      <div {...fieldProps} className='fs-4' style={styles}>
        {input.value}
      </div>
      <DisplayErrorOrHelperTextFeedback meta={meta} helperText={helperText} />
    </Form.Group>
  );
};
export const HcpPlainTextLabelField = (props: UseFieldApiConfig) => {
  const { label, helperText, input, meta, ...fieldProps } = useFieldApi(props);

  // styles borrowed from .form-input
  const styles = {
    height: 'calc(1.5em + 0.75rem + 2px)',
    lineHeight: 1.5,
    padding: '0.375rem 0'
  };
  const item_label = props.options
    .filter((item: AnyObject) => item?.value === input?.value)
    .map((item: AnyObject) => item?.label);
  return (
    <Form.Group as={Col} {...props.sizes}>
      {label && <Form.Label className='text-uppercase'>{label}</Form.Label>}
      <div {...fieldProps} className='fs-4' style={styles}>
        {item_label}
      </div>
      <DisplayErrorOrHelperTextFeedback meta={meta} helperText={helperText} />
    </Form.Group>
  );
};

export const HcpPlainTextBoldAndUnderlineField = (props: UseFieldApiConfig) => {
  const { label, helperText, input, meta, ...fieldProps } = useFieldApi(props);

  // styles borrowed from .form-input
  const styles = {
    height: 'calc(1.5em + 0.75rem + 2px)',
    lineHeight: 1.5,
    padding: '0.375rem 0'
  };
  return (
    <Form.Group as={Col} {...props.sizes}>
      <div {...fieldProps} className='fs-4' style={styles}>
        <strong>
          <u>{label}</u>
        </strong>
      </div>
      <DisplayErrorOrHelperTextFeedback meta={meta} helperText={helperText} />
    </Form.Group>
  );
};

export const HcpTextLabelField = (props: UseFieldApiConfig) => {
  const { label, helperText, input, meta, ...fieldProps } = useFieldApi(props);

  const textColor = fieldProps.color || '#8f8f8f';
  // styles borrowed from .form-input
  const styles = {
    height: 'calc(1.5em + 0.75rem + 2px)',
    lineHeight: 1.5,
    padding: '0.375rem 0',
    color: textColor
  };
  return (
    <Form.Group as={Col} {...props.sizes}>
      <div {...fieldProps} className='fs-4' style={styles}>
        {label}
      </div>
      <DisplayErrorOrHelperTextFeedback meta={meta} helperText={helperText} />
    </Form.Group>
  );
};

export const HcpTypeAheadTextField = (props: UseFieldApiConfig) => {
  const { label, helperText, input, meta, ...fieldProps } = useFieldApi(props);

  // styles borrowed from .form-input
  const styles = {
    height: 'calc(1.5em + 0.75rem + 2px)',
    lineHeight: 1.5,
    padding: '0.375rem 0'
  };
  const listItems =
    input && input.value && input.value.length > 0
      ? input?.value?.map((values: AnyObject) => <p key={values.value}>{values.label}</p>)
      : '';
  return (
    <Form.Group as={Col} {...props.sizes}>
      {label && <Form.Label className='text-uppercase'>{label}</Form.Label>}
      <div {...fieldProps} className='fs-4' style={styles}>
        {listItems}
      </div>
      <DisplayErrorOrHelperTextFeedback meta={meta} helperText={helperText} />
    </Form.Group>
  );
};

export const HcpAddRemoveFields = (props: UseFieldApiConfig) => {
  const form = useFormApi();
  const fieldProps = useFieldApi(props);

  const currentValue = (fieldProps.input.value as number) ?? 0;
  const maxFields = (fieldProps.maxFields as number) ?? 2;

  const onToggleRemove = () => {
    let value = form.getState().values[fieldProps.input.name] ?? 0;
    form.change(fieldProps.input.name, --value);
  };

  const onToggleAdd = () => {
    let value = form.getState().values[fieldProps.input.name] ?? 0;
    form.change(fieldProps.input.name, ++value);
  };

  return (
    <>
      <Form.Group as={Col} size={6}>
        {currentValue < maxFields && (
          <Button variant='outline-secondary' className='text-nowrap' onClick={onToggleAdd}>
            {fieldProps.addLabel ?? 'ADD ANOTHER'}
          </Button>
        )}
      </Form.Group>
      <Form.Group as={Col} size={6} className='text-right'>
        {currentValue > 0 && (
          <Button variant='transparent' className='text-danger text-nowrap' onClick={onToggleRemove}>
            <DashCircleFill className={'mr-3 flex-shrink-0 text-danger'} />
            &nbsp;{fieldProps.removeLabel ?? 'REMOVE THIS'}
          </Button>
        )}
      </Form.Group>
    </>
  );
};

export const Last4SSNInputField = (props: UseFieldApiConfig) => {
  const { label, helperText, input, meta, ...fieldProps } = useFieldApi(props);
  const optional = fieldProps.optional && meta.error === 'Required';
  return (
    <Form.Group as={Col} {...props.sizes} className='ssn-input-box'>
      {label && <Form.Label className='text-uppercase'>{label}</Form.Label>}

      <div className='ssn-placeholder'>
        <span>XXX</span>
        <span className='ssn-dash'>-</span>
        <span>XX</span>
        <span className='ssn-dash'>-</span>
      </div>
      {optional ? (
        <Form.Control {...input} {...fieldProps} className='ssn-input' />
      ) : (
        <Form.Control {...input} {...fieldProps} className='ssn-input' {...validationProps(meta)} />
      )}
      {!optional ? <DisplayErrorOrHelperTextFeedback meta={meta} helperText={helperText} className='ssn-error' /> : ''}
    </Form.Group>
  );
};

export const HcpLoginInputField = (props: UseFieldApiConfig) => {
  const { label, helperText, input, meta, passwordRequirementBox, ...fieldProps } = useFieldApi(props);
  const optional = fieldProps.optional && meta.error === 'Required';
  if (props.className) {
    return (
      <Form.Group as={Col} {...props.sizes} className={props.className}>
        {label && <Form.Label className='text-uppercase text-custom-50'>{label}</Form.Label>}
        {optional ? (
          <Form.Control {...input} {...fieldProps} className='' />
        ) : (
          <Form.Control {...input} {...fieldProps} className='' {...validationProps(meta)} />
        )}
        {!optional ? <DisplayErrorOrHelperTextFeedback meta={meta} helperText={helperText} /> : ''}
      </Form.Group>
    );
  }
  return (
    <Form.Group as={Col} {...props.sizes}>
      {label && <Form.Label className='text-uppercase'>{label}</Form.Label>}
      {passwordRequirementBox ? <InfoIcon arrow='arrow-top-left' {...fieldProps} /> : null}
      {optional ? (
        <Form.Control {...input} {...fieldProps} className='' />
      ) : (
        <Form.Control {...input} {...fieldProps} className='' {...validationProps(meta)} />
      )}
      {!optional ? <DisplayErrorOrHelperTextFeedback meta={meta} helperText={helperText} /> : ''}
    </Form.Group>
  );
};

export const HcpInputFieldWithInlineLabel = (props: UseFieldApiConfig) => {
  const { label, helperText, input, meta, passwordRequirementBox, ...fieldProps } = useFieldApi(props);
  const optional = fieldProps.optional && meta.error === 'Required';
  return (
    <Form.Group as={Col} {...props.sizes} className={props.className}>
      {label && <Form.Label className='text-uppercase mr-1'>{label}</Form.Label>}
      {optional ? (
        <Form.Control {...input} {...fieldProps} className='' style={{ display: 'inline', width: '94%' }} />
      ) : (
        <Form.Control
          {...input}
          {...fieldProps}
          className=''
          style={{ display: 'inline', width: '94%' }}
          {...validationProps(meta)}
        />
      )}
      {!optional ? <DisplayErrorOrHelperTextFeedback meta={meta} helperText={helperText} /> : ''}
    </Form.Group>
  );
};

export const HcpInputField = (props: UseFieldApiConfig) => {
  const { label, helperText, input, meta, passwordRequirementBox, ...fieldProps } = useFieldApi(props);
  const optional = fieldProps.optional && meta.error === 'Required';
  if (props.className) {
    return (
      <Form.Group as={Col} {...props.sizes} className={props.className}>
        {label && <Form.Label className='text-uppercase'>{label}</Form.Label>}
        {optional ? (
          <Form.Control {...input} {...fieldProps} className='' />
        ) : (
          <Form.Control {...input} {...fieldProps} className='' {...validationProps(meta)} />
        )}
        {!optional ? <DisplayErrorOrHelperTextFeedback meta={meta} helperText={helperText} /> : ''}
      </Form.Group>
    );
  }
  return (
    <Form.Group as={Col} {...props.sizes}>
      {label && <Form.Label className='text-uppercase'>{label}</Form.Label>}
      {passwordRequirementBox ? <InfoIcon arrow='arrow-top-left' {...fieldProps} /> : null}
      {optional ? (
        <Form.Control {...input} {...fieldProps} className='' />
      ) : (
        <Form.Control {...input} {...fieldProps} className='' {...validationProps(meta)} />
      )}
      {!optional ? <DisplayErrorOrHelperTextFeedback meta={meta} helperText={helperText} /> : ''}
    </Form.Group>
  );
};

// eslint-disable-next-line react/display-name
export const HcpNpiInputField = (props: UseFieldApiConfig) => {
  const { label, helperText, input, meta, ...fieldProps } = useFieldApi(props);
  return (
    <Form.Group as={Col} {...props.sizes}>
      {label && <Form.Label className='text-uppercase'>{label}</Form.Label>}
      <NumberFormat
        customInput={Form.Control}
        {...(input as AnyType)}
        {...fieldProps}
        {...validationProps(meta)}
        maxLength={props?.exact}
      />
      <DisplayErrorOrHelperTextFeedback meta={meta} helperText={helperText} />
    </Form.Group>
  );
};

// eslint-disable-next-line react/display-name
export const HcpFormatInputField = (format: string) => (props: UseFieldApiConfig) => {
  const { label, helperText, input, meta, ...fieldProps } = useFieldApi(props);
  return (
    <Form.Group as={Col} {...props.sizes}>
      {label && <Form.Label className='text-uppercase'>{label}</Form.Label>}
      <NumberFormat
        customInput={Form.Control}
        format={format}
        {...(input as AnyType)}
        {...fieldProps}
        {...validationProps(meta)}
      />
      <DisplayErrorOrHelperTextFeedback meta={meta} helperText={helperText} />
    </Form.Group>
  );
};

export const HcpCheckFieldForClinicalOptions = (props: UseFieldApiConfig) => {
  const form = useFormApi();
  const meetingCriteria = form?.getFieldState('patients_meeting_criteria_other')?.value;

  if (!meetingCriteria?.includes('hasHeFH')) {
    const hefhSelectedOptions = form?.getFieldState('hefh_selected_options')?.value;
    if (hefhSelectedOptions?.length > 0) {
      form.change(clinicalFields.hefhSelectedOptions, []);
    }
  }

  if (!meetingCriteria?.includes('other')) {
    const clinicalOtherValue = form?.getFieldState('clinical_other_selected_header')?.value;
    if (clinicalOtherValue !== '') {
      form.change(clinicalFields.clinicalOtherSelectedHeader, '');
    }
  }

  if (!meetingCriteria?.includes('hasASCVD')) {
    const clinicalASCVDValue = form?.getFieldState('clinical_patient_primary_diagnosed_select_another')?.value;
    if (clinicalASCVDValue) {
      form.change(clinicalFields.primaryDiagnosedSelectAnother, null);
    }
  }

  const hefhSelectedOptions = form?.getFieldState('hefh_selected_options')?.value;
  if (meetingCriteria && !hefhSelectedOptions?.includes('other')) {
    const hefhOtherValue = form?.getFieldState('clinical_hefh_other_selected')?.value;
    if (hefhOtherValue !== '') {
      form.change(clinicalFields.clinicalHefhOtherSelected, '');
    }
  }

  const { helperText, meta, customClassName } = useFieldApi(props);
  const checkboxSectionClassName = customClassName ? `hcp-checkbox ${customClassName}` : 'hcp-checkbox';
  return (
    <Form.Group as={Col} className={checkboxSectionClassName} {...props.sizes}>
      <suirComponentMapper.checkbox {...(props as AnyType)} className={props.optionsClassName || ''} />
      <DisplayErrorOrHelperTextFeedback meta={meta} helperText={helperText} />
    </Form.Group>
  );
};

export const HcpCheckField = (props: UseFieldApiConfig) => {
  const { helperText, meta, customClassName } = useFieldApi(props);
  const checkboxSectionClassName = customClassName ? `hcp-checkbox ${customClassName}` : 'hcp-checkbox';
  return (
    <Form.Group as={Col} className={checkboxSectionClassName} {...props.sizes}>
      <suirComponentMapper.checkbox {...(props as AnyType)} className={props.optionsClassName || ''} />
      <DisplayErrorOrHelperTextFeedback meta={meta} helperText={helperText} />
    </Form.Group>
  );
};
//This is an inline checkbox with required capability
export const HcpInlineCheckBoxWithContent = (props: UseFieldApiConfig) => {
  const {
    disabled,
    content,
    input,
    meta: { error, touched }
  } = useFieldApi(props);

  return (
    <Form.Group as={Col} className='hcp-checkbox' {...props.sizes}>
      <div className='inline-checkbox'>
        <input id={input.name} {...input} type='checkbox' checked={input.value} disabled={disabled === 'true'} />
        <label {...rawCleanHtmlProps(content)} htmlFor={props.name} />
      </div>
      {touched && error && <p className='invalid-feedback'>{error}</p>}
    </Form.Group>
  );
};

export const HcpRadioField = (props: UseFieldApiConfig) => {
  const defaultClassName = 'hcp-radio inline-radio';
  const className = `${props?.className} ${defaultClassName}` || `${defaultClassName}`;
  return (
    // TODO: `inline-radio` should be applied elsewhere; feel free to rename it
    <Form.Group as={Col} className={className} {...props.sizes}>
      <suirComponentMapper.radio {...(props as AnyType)} />
    </Form.Group>
  );
};

export const HcpClinicalRadioField = (props: UseFieldApiConfig) => {
  const defaultClassName = 'hcp-radio inline-radio';
  const className = `${props?.className} ${defaultClassName}` || `${defaultClassName}`;
  const { label, helperText, input, meta, ...fieldProps } = useFieldApi(props);
  const form = useFormApi();
  const e78Radio = form.getState().values[clinicalFields?.E78Diagnosed];
  const onClinicalScreenClean = () => {
    if (e78Radio == 'e78') {
      form.change(clinicalFields.clinical12TextOne, '');
      form.change(clinicalFields.clinical12TextTwo, '');
      form.change(clinicalFields.clinicalAscvd, false);
      form.change(clinicalFields.clinicalOtherRiskFactors, false);
      form.change(clinicalFields.clinical12, false);
      form.change(clinicalFields.clinicalDiabetes11Diagnosis, false);
      form.change(clinicalFields.clinicalDiabetesMellitus, '');
      form.change(clinicalFields.clinical16CerebrovascularDiagnosis, false);
      form.change(clinicalFields.clinical16CerebrovascularTextOne, '');
      form.change(clinicalFields.clinical16CerebrovascularTexttwo, '');
      form.change(clinicalFields.clinicalHyperTensionI10Diagnosis, false);
      form.change(clinicalFields.clinicalHyperTension, '');
      form.change(clinicalFields.clinicalAtherosClerosisI70Diagnosis, false);
      form.change(clinicalFields.clinicalAtherosClerosisText, '');
      form.change(clinicalFields.clinicalSpecifyICD10cmDiagnosis, false);
      form.change(clinicalFields.clinicalOther_SpecifyICD_10_cm, '');
      form.change(clinicalFields.clinicalOtherPeripheralVasculari73Diagnosis, false);
      form.change(clinicalFields.clinicalPeripheral1Vasculari173, '');
      form.change(clinicalFields.clinicalOtherSpecifyTextSection, '');
      form.change(clinicalFields.clinicalOtherSpecifyICD, false);
      form.change(clinicalFields.clinicalOthersSpecifyICDText, '');
      form.change(clinicalFields.clinicalOtherspecifyICD, '');
      form.change(clinicalFields.clinicalZ8342, false);
      form.change(clinicalFields.clinicalE755, false);
      form.change(clinicalFields.clinicalOtherICD, false);
      form.change(clinicalFields.clinicalSpecifyOuterText, '');
      form.change(clinicalFields.clinicalOther, '');
      form.change(clinicalFields.E78Select, 'Select One');
    }
    if (e78Radio == 'e7801') {
      form.change(clinicalFields.clinicalZ8342, false);
      form.change(clinicalFields.clinicalE755, false);
      form.change(clinicalFields.clinicalOtherICD, false);
      form.change(clinicalFields.clinicalSpecifyOuterText, '');
      form.change(clinicalFields.clinicalOther, '');
    }
  };

  return (
    // TODO: `inline-radio` should be applied elsewhere; feel free to rename it
    <Form.Group as={Col} className={className} {...props.sizes}>
      <suirComponentMapper.radio {...(props as AnyType)} onClick={onClinicalScreenClean} />
    </Form.Group>
  );
};

export const HcpSelectField = (props: UseFieldApiConfig) => {
  const { label, helperText, input, optional, meta, className, ...fieldProps } = useFieldApi(props);
  return (
    <Form.Group as={Col} className={className} {...props.sizes}>
      {label && <Form.Label className='text-uppercase'>{label}</Form.Label>}
      {optional ? (
        <Form.Control as='select' {...input} {...fieldProps}>
          <option value=''>--Select One--</option>
          {props.options?.map((option: { value: string; label?: string }, i: number) => (
            <option key={i} value={option.value}>
              {option?.label}
            </option>
          ))}
        </Form.Control>
      ) : (
        <Form.Control as='select' {...input} {...fieldProps} {...validationProps(meta)}>
          <option value=''>--Select One--</option>
          {props.options?.map((option: { value: string; label?: string }, i: number) => (
            <option key={i} value={option.value}>
              {option?.label}
            </option>
          ))}
        </Form.Control>
      )}
      {!optional ? <DisplayErrorOrHelperTextFeedback meta={meta} helperText={helperText} /> : ''}
    </Form.Group>
  );
};

export const HcpTypeaheadField = (props: UseFieldApiConfig) => {
  const { label, helperText, input, meta, ...fieldProps } = useFieldApi(props);
  const { getState } = useFormApi();
  const { values } = getState();
  let isCptCodeLengthReached = false;
  if (input.name === 'clinical_cpt_code') {
    values?.clinical_cpt_code?.length == 5 ? (isCptCodeLengthReached = true) : (isCptCodeLengthReached = false);
  }

  const renderTypeaheadActions = ({ onClear, selected }: AnyObject) => {
    const clearInput = () => {
      onClear();
    };

    return (
      <div className='rbt-aux'>
        {!!selected.length && (
          <button type='reset' className='bg-transparent border-0 p-0' onClick={clearInput}>
            <X tabIndex={0} className='pointer fs-6 my-auto' />
          </button>
        )}
      </div>
    );
  };

  return (
    <Form.Group as={Col} {...props.sizes}>
      {label && <Form.Label className='text-uppercase'>{label}</Form.Label>}
      {input.name === 'clinical_cpt_code' ? (
        <Typeahead
          disabled={isCptCodeLengthReached}
          id={`${input.name}_typeahead`}
          {...(input as unknown)}
          {...((fieldProps as unknown) as TypeaheadProps<string[]>)}
          selected={values?.clinical_cpt_code?.length > 0 ? values?.clinical_cpt_code : []}>
          {renderTypeaheadActions}
        </Typeahead>
      ) : input.name === 'patient_insurances' ? (
        <Typeahead
          disabled={isCptCodeLengthReached}
          id={`${input.name}_typeahead`}
          {...(input as unknown)}
          {...((fieldProps as unknown) as TypeaheadProps<string[]>)}
          selected={values?.patient_insurances}>
          {renderTypeaheadActions}
        </Typeahead>
      ) : (
        <Typeahead
          disabled={isCptCodeLengthReached}
          id={`${input.name}_typeahead`}
          {...(input as unknown)}
          {...((fieldProps as unknown) as TypeaheadProps<string[]>)}
          // Falsy values can cause issues in some cases, such as formWizard navigation:
          defaultSelected={props.defaultSelected || []}>
          {renderTypeaheadActions}
        </Typeahead>
      )}
      <DisplayErrorOrHelperText meta={meta} helperText={helperText} />
    </Form.Group>
  );
};

export const HcpFileNameField = (props: UseFieldApiConfig) => {
  const { label, input, ...fieldProps } = useFieldApi(props);

  if (!input.value) {
    return null;
  }
  const fileBadges = input?.value?.map((item: FilePreview, index: number) => (
    <span className='text-truncate' key={index}>
      {item?.path}
    </span>
  ));
  return (
    <Form.Group as={Col} {...props.sizes}>
      {label && <Form.Label className='text-uppercase'>{label}</Form.Label>}
      <div {...fieldProps} className='fs-4 mb-2'>
        {fileBadges}
      </div>
    </Form.Group>
  );
};

export const HcpFileUploadField = (props: UseFieldApiConfig) => {
  const { helperText, input, meta, ...fieldProps } = useFieldApi(props);
  return (
    <Form.Group as={Col} {...props.sizes}>
      <HcpUpload
        {...input}
        {...(fieldProps as AnyType)}
        value={input.value}
        validate={props.validate}
        showRequiredText={props.showRequiredText}
      />
      <DisplayErrorOrHelperTextFeedback meta={meta} helperText={helperText} />
    </Form.Group>
  );
};

export const HcpFormFileMedicscanField = (props: AnyType) => {
  const { helperText, input, meta, ...fieldProps } = useFieldApi(props);
  return (
    <Form.Group as={Col} {...props.sizes}>
      <HcpMedicscanUpload {...input} {...(fieldProps as AnyType)} value={input.value} />
      <DisplayErrorOrHelperTextFeedback meta={meta} helperText={helperText} />
    </Form.Group>
  );
};

export const HcpScrollField = (props: UseFieldApiConfig) => {
  const { change } = useFormApi();
  const [isBottom, setBottom] = useState(false);
  const { className, content, helperText, input, meta, ...fieldProps } = useFieldApi(props);

  useEffect(() => {
    change(input.name, isBottom);
  }, [isBottom]);

  const onScrollToBottom = useCallback(() => {
    if (!isBottom) setBottom(true);
  }, [isBottom, setBottom]);

  return (
    <Form.Group as={Col} {...props.sizes}>
      <ContentForRequiredScroll className={className ?? 'p-3 fs-2'} onScrollToBottom={onScrollToBottom}>
        <div {...input} {...fieldProps} {...rawCleanHtmlProps(content)} />
      </ContentForRequiredScroll>
      <DisplayErrorOrHelperTextFeedback meta={meta} helperText={helperText} />
    </Form.Group>
  );
};

export const SVGIcon = () => {
  return (
    <svg
      aria-hidden='true'
      focusable='false'
      data-prefix='fas'
      data-icon='angle-down'
      className='custom-dropdown-icon'
      role='img'
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 320 512'>
      <path
        fill='currentColor'
        d='M143 352.3L7 216.3c-9.4-9.4-9.4-24.6 0-33.9l22.6-22.6c9.4-9.4 24.6-9.4 33.9 0l96.4 96.4 96.4-96.4c9.4-9.4 24.6-9.4 33.9 0l22.6 22.6c9.4 9.4 9.4 24.6 0 33.9l-136 136c-9.2 9.4-24.4 9.4-33.8 0z'></path>
    </svg>
  );
};

export const HcpTypeaheadFieldForNDCWithDropdownIcon = (props: UseFieldApiConfig) => {
  const { label, helperText, input, meta, ...fieldProps } = useFieldApi(props);
  const form = useFormApi();

  const renderTypeaheadActions = ({ selected, onClear, toggleMenu }: AnyObject) => {
    const clearInput = () => {
      onClear();
    };

    const showList = () => {
      toggleMenu();
    };

    return (
      <div className='rbt-aux'>
        {!!selected.length && (
          <button type='reset' className='bg-transparent border-0 p-0' onClick={clearInput}>
            <X tabIndex={0} className='pointer fs-6 my-auto' />
          </button>
        )}
      </div>
    );
  };
  if (
    (typeof input?.value === 'object' && input?.value?.[0]?.label) ||
    (input?.value && typeof input?.value !== 'object')
  ) {
    const ndcLabel = input?.value?.[0]?.label || input?.value;
    return (
      <Form.Group as={Col} {...props.sizes}>
        {label && <Form.Label className='text-uppercase'>{label}</Form.Label>}
        <InputGroup className='border-left-0'>
          <InputGroup.Prepend>{searchIcon}</InputGroup.Prepend>
          <Typeahead
            id={`${input.name}_typeahead`}
            {...(input as unknown)}
            {...((fieldProps as unknown) as TypeaheadProps<string[]>)}
            defaultSelected={props.defaultSelected || [ndcLabel]}
            minLength={2}
            inputProps={{
              className: 'border-left-0'
            }}
            onChange={(selection: AnyType) => {
              if (selection.length) {
                form.change(clinicalFields.ndc, selection?.[0].label);
                form.change(clinicalFields.qty, selection?.[0].value?.quantity);
                form.change(clinicalFields.daysSupply, selection?.[0].value?.daysSupply);
              } else {
                form.change(clinicalFields.ndc, null);
                form.change(clinicalFields.qty, '');
                form.change(clinicalFields.daysSupply, '');
              }
            }}>
            {renderTypeaheadActions}
          </Typeahead>
        </InputGroup>
        <DisplayErrorOrHelperText meta={meta} helperText={helperText} />
      </Form.Group>
    );
  }
  return (
    <Form.Group as={Col} {...props.sizes}>
      {label && <Form.Label className='text-uppercase'>{label}</Form.Label>}
      <InputGroup className='border-left-0'>
        <InputGroup.Prepend>{searchIcon}</InputGroup.Prepend>
        <Typeahead
          id={`${input.name}_typeahead`}
          {...(input as unknown)}
          {...((fieldProps as unknown) as TypeaheadProps<string[]>)}
          defaultSelected={props.defaultSelected || []}
          minLength={2}
          inputProps={{
            className: 'border-left-0'
          }}
          onChange={(selection: AnyType) => {
            form.change(clinicalFields.ndc, selection?.[0].label);
            form.change(clinicalFields.qty, selection?.[0].value?.quantity);
            form.change(clinicalFields.daysSupply, selection?.[0].value?.daysSupply);
          }}>
          {renderTypeaheadActions}
        </Typeahead>
      </InputGroup>
      <DisplayErrorOrHelperText meta={meta} helperText={helperText} />
    </Form.Group>
  );
};

export const HcpTypeaheadFieldiWithDropdownIcon = (props: UseFieldApiConfig) => {
  const { label, helperText, input, meta, ...fieldProps } = useFieldApi(props);
  const form = useFormApi();

  const renderTypeaheadActions = ({ selected, onClear, toggleMenu }: AnyObject) => {
    const clearInput = () => {
      onClear();
    };

    const showList = () => {
      toggleMenu();
    };

    return (
      <div className='rbt-aux'>
        {!!selected.length && (
          <button type='reset' className='bg-transparent border-0 p-0' onClick={clearInput}>
            <X tabIndex={0} className='pointer fs-6 my-auto' />
          </button>
        )}
        {selected.length <= 0 ? (
          <button type='button' className='bg-transparent border-0 p-0' onClick={showList}>
            <SVGIcon />
          </button>
        ) : null}
      </div>
    );
  };
  if (
    (typeof input?.value === 'object' && input?.value?.[0]?.label) ||
    (input?.value && typeof input?.value !== 'object')
  ) {
    const payerNameLabel = input?.value?.[0]?.label || input?.value;
    return (
      <Form.Group as={Col} {...props.sizes}>
        {label && <Form.Label className='text-uppercase'>{label}</Form.Label>}
        <Typeahead
          id={`${input.name}_typeahead`}
          {...(input as unknown)}
          {...((fieldProps as unknown) as TypeaheadProps<string[]>)}
          // Falsy values can cause issues in some cases, such as formWizard navigation:
          defaultSelected={props.defaultSelected || [payerNameLabel]}>
          {renderTypeaheadActions}
        </Typeahead>
        <DisplayErrorOrHelperText meta={meta} helperText={helperText} />
      </Form.Group>
    );
  }
  return (
    <Form.Group as={Col} {...props.sizes}>
      {label && <Form.Label className='text-uppercase'>{label}</Form.Label>}
      <Typeahead
        id={`${input.name}_typeahead`}
        {...(input as unknown)}
        //{...(input?.value?.[0]?.label ?? (input as unknown))}
        {...((fieldProps as unknown) as TypeaheadProps<string[]>)}
        // Falsy values can cause issues in some cases, such as formWizard navigation:
        defaultSelected={props.defaultSelected || []}>
        {renderTypeaheadActions}
      </Typeahead>
      <DisplayErrorOrHelperText meta={meta} helperText={helperText} />
    </Form.Group>
  );
};

// HCP Raw Text Fields
export const HCPTextWithRawHtml = (props: UseFieldApiConfig, clazzName: string, styles: AnyObject) => {
  const fieldProps = useFieldApi(props);
  return (
    <Col {...props.sizes}>
      <div {...fieldProps} {...rawHtmlProps(fieldProps?.content)} className={clazzName} style={styles} />
    </Col>
  );
};

export const HCPItalicTextSectionWithRawHtml = (props: UseFieldApiConfig) => {
  const styles = {
    color: 'gray',
    marginLeft: '10%'
  };
  return HCPTextWithRawHtml(props, 'fs-3 font-italic', styles);
};

export const HCPPlainTextSectionWithRawHtml = (props: UseFieldApiConfig) => {
  const styles = {};
  return HCPTextWithRawHtml(props, `${props?.classname}`, styles);
};
// End of HCP Raw Text Fields

export const HCPDatePickerWithTime = (props: UseFieldApiConfig) => {
  const { label, helperText, input, meta, placeholder, ...fieldProps } = useFieldApi(props);
  const form = useFormApi();
  // date range for datepicker
  const minDate = new Date();
  minDate.setFullYear(minDate.getFullYear() - 1);
  const maxDate = new Date();
  maxDate.setFullYear(maxDate.getFullYear() + 1);
  const optional = fieldProps.optional && meta.error === 'Required';
  return (
    <Form.Group as={Col} {...fieldProps} className='col-md-4'>
      {label && <Form.Label className='text-uppercase'>{label}</Form.Label>}
      <ReactDatePicker
        minDate={minDate}
        maxDate={maxDate}
        className='form-control'
        showTimeInput
        placeholderText={placeholder ?? ''}
        selected={form.getState().values[rxAdminDoseFields?.adminDate]}
        onChange={(date: AnyType) => {
          form.change(rxAdminDoseFields.adminDate, date);
        }}
        {...fieldProps}
        {...validationProps(meta)}
      />
      {!optional ? <DisplayErrorOrHelperTextFeedback meta={meta} helperText={helperText} /> : null}
    </Form.Group>
  );
};
export const componentMapper = {
  'plain-text-section': HcpPlainTextSection,
  'italic-text-section': HCPItalicTextSection,
  'service-request-review': ReviewTab,
  'title-section': HcpTitleSection,
  'title-wrap-section': HcpTitleSectionWithWrap,
  [componentTypes.CHECKBOX]: HcpCheckField,
  'inline-check-box-with-content': HcpInlineCheckBoxWithContent,
  [componentTypes.DATE_PICKER]: HcpInputField,
  [componentTypes.PLAIN_TEXT]: HcpPlainTextField,
  [componentTypes.RADIO]: HcpRadioField,
  [componentTypes.SELECT]: HcpSelectField,
  [componentTypes.TEXT_FIELD]: HcpInputField,
  [componentTypes.WIZARD]: HcpFormWizard,
  'address-location': HcpAddressLocationTextField,
  'type-text-field': HcpPlainTextTypeField,
  scroll: HcpScrollField,
  section: HcpSection,
  separator: HcpSeparator,
  spacer: HcpSpacer,
  tax: HcpFormatInputField('##-#######'),
  tel: HcpFormatInputField('(###) ###-####'),
  typeahead: HcpTypeaheadField,
  typeaheadtext: HcpTypeAheadTextField,
  plaintextlabel: HcpPlainTextLabelField,
  upload: HcpFileUploadField,
  filename: HcpFileNameField,
  zip: HcpFormatInputField('#####'),
  medicscan: HcpFormFileMedicscanField,
  'npi-input-field': HcpNpiInputField,
  'add-remove-fields': HcpAddRemoveFields,
  'typeahead-with-dropdown-icon': HcpTypeaheadFieldiWithDropdownIcon,
  'plain-text-multiple-select-section': HcpPlainTextMultipleSelectSection,
  'signature-field': HCPSignatureField,
  'plain-signature-field': HCPPlainSignatureField,
  'plain-signature-date-field': HCPPlainSignatureDateField,
  'italic-text-section-with-raw-html': HCPItalicTextSectionWithRawHtml,
  'plain-text-bold-underlined': HcpPlainTextBoldAndUnderlineField,
  'text-label': HcpTextLabelField,
  header_with_password_requirement: HcpHeaderWithPasswordRequirement,
  'last-4-ssn-input-field': Last4SSNInputField,
  'login-input-filed': HcpLoginInputField,
  'typeahead-ndc-dropdown': HcpTypeaheadFieldForNDCWithDropdownIcon,
  'plain-text-field-diagnosis-list-items': HcpPlainTextFieldDiagnosisListItems,
  'input-field-with-inline-label': HcpInputFieldWithInlineLabel,
  'raw-html-text': HCPPlainTextSectionWithRawHtml,
  'check-field-for-clinical-options': HcpCheckFieldForClinicalOptions,
  'clinical-radio': HcpClinicalRadioField,
  [componentTypes.TIME_PICKER]: HCPDatePickerWithTime,
  ...asyncComponentMapper
};
